import React, { useState, useEffect } from "react";
import { createClient } from "pexels";
import tw from "twin.macro";
import styled from "styled-components";

const client = createClient(
  "563492ad6f9170000100000116a956d7b7a74b3697eb610bffb52d7d"
);

const HeaderRow = tw.div`flex justify-between items-center flex-col`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded-md leading-none mt-12`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition-all duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-sky-500! text-gray-100!`}
  }
`;

const SearchContainer = tw.span`h-12 mx-auto`;
const SearchField = tw.input`h-12 mt-6 rounded-l-md border-l-2 border-t-2 border-b-2 px-2`;
const SearchButton = tw.button`h-12 mr-4 border-2 px-4 rounded-r-md bg-sky-500 text-gray-100 hocus:bg-sky-700 font-bold hocus:text-gray-200  focus:outline-none transition-all duration-300`;

const Root = tw.div`z-50 left-0 fixed w-screen h-screen p-10 top-0`;
const BlackWaze = tw.div`z-50 left-0 fixed bg-gray-800 opacity-50 w-screen h-full top-0`;

const Window = styled.div`
  ${tw`bg-gray-100 flex flex-col rounded-xl opacity-100 p-10 pt-1 `}
  max-height:90vh;
`;
const Title = tw.h3` h-12 text-gray-900 text-4xl sm:text-5xl  text-black tracking-wide text-center`;

const ImagesGrid = tw.div`mt-10 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5`;
const Image = styled.img`
  ${tw`rounded-md shadow-lg cursor-pointer  hover:outline-none transition-all duration-300`}
  ${(props) => props.landscape && ``}
`;

const Top = tw.div` mt-4 flex justify-between p-0 h-12 text-center content-center align-middle items-center`;

const CloseButton = tw.button`w-12 h-12 text-lg text-center items-center flex justify-center`;

const Bottom = tw.div`flex justify-between`;
const Pagination = tw.div`flex gap-2 mt-4`;
const CurrentPage = tw.button`h-12 w-12 border-2 p-2 rounded-md bg-sky-500 text-gray-100 hocus:bg-sky-700 font-bold hocus:text-gray-200  focus:outline-none transition-all duration-300`;
const DisabledPage = tw.button`h-12 w-12 border-2 p-2 rounded-md bg-gray-200 text-gray-100`;
export const StockPhoto = (props) => {
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [results, setResults] = useState();
  const [usePortrait, setUsePortrait] = useState(false);
  const [activePage, setPage] = useState(1);

  //Initialize the tabs we have and current active
  const tabsKeys = ["Stock foto's", "Afbeeldingsuploads", "Documenten"];
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  useEffect(() => {
    if (results) setTotalPages(Math.floor(results.total_results / 15));
  }, [results]);

  useEffect(() => {
    if (results) Retrieve(query);
  }, [currentPage]);

  function Retrieve(query) {
    client.photos
      .search({ query, per_page: 15, locale: "nl-NL", page: currentPage })
      .then((photos) => {
        setResults(photos);
        console.log(results);
      });
  }

  return (
    <>
      <SearchContainer>
        <SearchField
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search for image..."
        ></SearchField>
        <SearchButton onClick={() => Retrieve(query)}>Search</SearchButton>
        {/* <Toggle
          text="Portretmodus: "
          parentMethod={async () => {
            setUsePortrait(!usePortrait);
          }}
          active={usePortrait}
        /> */}
      </SearchContainer>
      <ImagesGrid>
        {results &&
          results.photos.map((photo) => (
            <Image
              //   landscape={photo.width > photo.heigth ? true : false}
              src={usePortrait ? photo.src.portrait : photo.src.landscape}
              onClick={() => {
                props.chooseMethod(
                  usePortrait ? photo.src.portrait : photo.src.landscape
                );
              }}
            ></Image>
          ))}
      </ImagesGrid>

      {totalPages && (
        <Bottom>
          <span></span>
          <Pagination>
            {currentPage - 1 > 0 && (
              <CurrentPage onClick={() => setCurrentPage(currentPage - 1)}>
                {currentPage - 1}
              </CurrentPage>
            )}
            <DisabledPage disabled>{currentPage}</DisabledPage>
            {currentPage + 1 < totalPages && (
              <CurrentPage onClick={() => setCurrentPage(currentPage + 1)}>
                {currentPage + 1}
              </CurrentPage>
            )}
          </Pagination>
        </Bottom>
      )}
    </>
  );
};
