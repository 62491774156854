import "styles/globalStyles.css";
import "@fontsource/inter";
import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { IKContext } from "imagekitio-react";
import { Notifications } from "@mantine/notifications";
// import "./assets/main.css";
//Import firebase
import "./index.css";
//Import firebase
import { initializeApp } from "firebase/app";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import CISMainLandingPage from "pages/CISMainLandingPage.js";
import { MantineProvider } from "@mantine/core";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Navbar } from "NewNavbar";

const Algemeen = lazy(() => import("pages/Algemeen"));
const GesprekIsrael = lazy(() => import("pages/GesprekIsrael"));
const LerenIsrael = lazy(() => import("pages/LerenIsrael"));
const DienenIsrael = lazy(() => import("pages/DienenIsrael"));
const Contact = lazy(() => import("pages/Contact"));
const Onderwijsprojecten = lazy(() => import("pages/Onderwijsprojecten"));
const English = lazy(() => import("pages/English"));

// Old CMS imports
const ContentPage = lazy(() => import("pages/ContentPage"));
// // const Dashboard = lazy(() => import("pages/Dashboard"));
// const MoreContentPage = lazy(() => import("pages/MeerContent"));

// New CMS imports
const Dashboard = React.lazy(() => import("./CMS/pages/Dashboard"));
const Editor = React.lazy(() => import("./CMS/pages/Editor"));
const More = React.lazy(() => import("./CMS/pages/More"));

//Initialize firebase
const firebaseConfig = {
  apiKey: "AIzaSyApgQvDLkm4bP8LZvhGdVrUCQWhW6IQ-Kk",
  authDomain: "cis-db-e9fd3.firebaseapp.com",
  projectId: "cis-db-e9fd3",
  storageBucket: "cis-db-e9fd3.appspot.com",
  messagingSenderId: "897662013626",
  appId: "1:897662013626:web:1bde41c59f1436c0ee3283",
  measurementId: "G-C5YVQ7ZLEF",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const functions = getFunctions(app);

// functions.useFunctionsEmulator("http://localhost:5001");

export default function App() {
  // Use a temp id for when there is no user
  // Keep track of the current logged in user
  // New user tracking
  const [user, setUser] = useState();
  const [metadata, setMetaData] = useState();
  const [loadedMetadata, setLoadedMetadata] = useState(false);
  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      setUser(currentUser);
    }
  });

  var id = "None";
  if (user) id = user.uid;
  const usersRef = doc(db, "users", id);
  const [userData, loading, error] = useDocumentData(usersRef);

  const [analytics, setAnalytics] = useState();
  // const analytics = firebase.analytics();

  //Load a global metadata doc since we need it a lot
  // Not tested
  useEffect(() => {
    if (!loadedMetadata) {
      setLoadedMetadata(true);
      const onSnap = onSnapshot(doc(db, "metadata", "batching"), (docu) => {
        setMetaData(docu.data());
      });
    }
  }, []);

  const [eventsWithUrls, setEventsWithUrls] = useState();
  const [loadedEventsWithUrls, setLoadedEventsWithUrls] = useState(false);

  // const analytics = firebase.analytics();

  //Load a global metadata doc since we need it a lot
  // Not tested
  useEffect(() => {
    if (!loadedEventsWithUrls) {
      setLoadedEventsWithUrls(true);
      const onSnap = onSnapshot(
        doc(db, "metadata", "eventsWithUrls"),
        (docu) => {
          setEventsWithUrls(docu.data());
        }
      );
    }
  }, []);

  const width = window.innerWidth;
  if (!getCookieConsentValue()) window["ga-disable-UA-113362003-1"] = true;

  const contentPage = (
    <ContentPage
      auth={auth}
      firebase={app}
      functions={functions}
      firestore={db}
      user={user}
      userData={userData}
      metadataDoc={metadata}
      navbar={<Navbar black></Navbar>}
    ></ContentPage>
  );

  const editor = (
    <Editor
      metadata={metadata}
      db={db}
      user={user}
      isValidMail={true}
      firebase={app}
      functions={functions}
      auth={auth}
      addDataPoints={[]}
      // For old functions which expect imgs not in imgs/ folder
      // replaceBucketImgs={"unimix"}
      analytics={analytics}
      app={app}
      maxPostsInSection={20}
      navbar={<Navbar absolute></Navbar>}
      // navbar={
      //   <NavBar
      //     addCss={"bg-slate-50"}
      //     noCall
      //     standalone
      //     analytics={analytics}
      //   ></NavBar>
      // }
    />
  );

  return (
    <>
      <CookieConsent
        enableDeclineButton
        onAccept={() => {
          setAnalytics(analytics);
          window["ga-disable-UA-113362003-1"] = false;
        }}
      >
        This website uses cookies for google analytics.
      </CookieConsent>

      <MantineProvider>
        <Notifications />
        <IKContext urlEndpoint="https://ik.imagekit.io/cis">
          <Router>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes className="font-family-inter">
                {" "}
                <Route path="/blogs/:slugpart/" element={contentPage}>
                  <Route path=":mode/" element={contentPage}>
                    <Route path=":newarticle" element={contentPage}></Route>
                  </Route>
                </Route>
                <Route
                  path="/english/:section?"
                  element={
                    <English
                      auth={auth}
                      firebase={app}
                      functions={functions}
                      firestore={db}
                      user={user}
                    ></English>
                  }
                />
                {/* <Route
                path="/search"
                element={(
                  <SearchBox
                    
                    auth={auth}
                    firebase={app}
                    functions={functions}
                    firestore={db}
                    user={user}
                    isMobile={width < 1028}
                  ></SearchBox>
                )}
              /> */}
                <Route
                  path="/over-ons/:section?"
                  element={
                    <Algemeen
                      auth={auth}
                      firebase={app}
                      functions={functions}
                      firestore={db}
                      user={user}
                    ></Algemeen>
                  }
                />
                <Route
                  path="/ontmoeten/:section?"
                  element={
                    <GesprekIsrael
                      auth={auth}
                      firebase={app}
                      functions={functions}
                      firestore={db}
                      user={user}
                    ></GesprekIsrael>
                  }
                />
                <Route
                  path="/leren/:section?"
                  element={
                    <LerenIsrael
                      metadataDoc={metadata}
                      auth={auth}
                      firebase={app}
                      functions={functions}
                      firestore={db}
                      user={user}
                      userData={userData}
                    ></LerenIsrael>
                  }
                />
                <Route
                  path="/joodsebijbeluitleg/:section?"
                  element={
                    <Onderwijsprojecten
                      auth={auth}
                      metadataDoc={metadata}
                      firebase={app}
                      functions={functions}
                      firestore={db}
                      user={user}
                      userData={userData}
                    ></Onderwijsprojecten>
                  }
                />
                <Route
                  path="/dienen/:section?"
                  element={
                    <DienenIsrael
                      auth={auth}
                      metadataDoc={metadata}
                      firebase={app}
                      functions={functions}
                      firestore={db}
                      user={user}
                    ></DienenIsrael>
                  }
                />
                <Route
                  path="/contact/:section?"
                  element={
                    <Contact
                      auth={auth}
                      userData={userData}
                      firebase={app}
                      functions={functions}
                      firestore={db}
                      user={user}
                    ></Contact>
                  }
                />
                <Route
                  path="/dashboard/:tab?"
                  element={
                    <>
                      {" "}
                      <Navbar black></Navbar>
                      <Dashboard
                        metadata={metadata}
                        db={db}
                        user={user}
                        signInSuccessUrl="dashboard"
                        firebase={app}
                        functions={functions}
                        auth={auth}
                        analytics={analytics}
                        isValidMail={true}
                        userData={userData}
                        buttonStyles="bg-sky-500 hover:bg-sky-600 text-white"
                      />
                    </>
                  }
                />
                {/* <Route
                path="/sitemap.xml"
                component={() => {
                  window.location.href =
                    "https://firebasestorage.googleapis.com/v0/b/cis-db-e9fd3.appspot.com/o/sitemap.xml?alt=media&token=access";
                  return null;
                }}
              /> */}
                <Route path="/editpost/:name" element={editor} />
                {/* <Route
                path="/posts/:name"
                element={(
                  <Post
                    
                    metadata={metadata}
                    db={db}
                    user={user}
                    firebase={app}
                    functions={functions}
                    auth={auth}
                    analytics={analytics}
                  />
                )}
              /> */}
                {/* <Route
                  exact
                  path="/meer/:author?/:category?/:publicist?"
                  element={(
                    <MoreContentPage
                      
                      auth={auth}
                      firebase={app}
                      functions={functions}
                      firestore={db}
                      
                      userData={userData}
                      metadataDoc={metadata}
                    ></MoreContentPage>
                  )}
                /> */}
                <Route
                  path="/meer"
                  element={
                    <>
                      <Navbar black absolute></Navbar>
                      <More
                        metadata={metadata}
                        db={db}
                        user={user}
                        firebase={app}
                        functions={functions}
                        auth={auth}
                        analytics={analytics}
                      />
                    </>
                  }
                />
                {/* <Route
                path="/succes"
                element={(
                  <>
                    <p>Het CIS dankt u hartelijk voor uw betaling.</p>
                    <PrimaryButton
                      onClick={() =>
                        window.location.replace("https://hetcis.nl/")
                      }
                    >
                      Terug naar Home
                    </PrimaryButton>
                  </>
                )}
              /> */}
                {/* <Route
                path="/cancel"
                element={(
                  <>
                    <p>De betaling is geannuleerd.</p>{" "}
                    <PrimaryButton
                      onClick={() =>
                        window.location.replace("https://hetcis.nl/")
                      }
                    >
                      Terug naar Home
                    </PrimaryButton>
                  </>
                )}
              /> */}
                <Route
                  path="/:section?"
                  element={
                    <>
                      <CISMainLandingPage
                        auth={auth}
                        metadata={metadata}
                        firebase={app}
                        functions={functions}
                        firestore={db}
                        user={user}
                        userData={userData}
                        eventsWithUrls={eventsWithUrls}
                      ></CISMainLandingPage>
                    </>
                  }
                />
              </Routes>
            </Suspense>
          </Router>
        </IKContext>
      </MantineProvider>
    </>
  );
}
