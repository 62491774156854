import * as Scroll from "react-scroll";
import { animateScroll as scroll, scroller } from "react-scroll";

//Simple function to scroll to an element on a page
export const ScrollTo = (name) => {
  if (name === "onderwijs") {
    MoveTo(name);
    return;
  }
  if (name === "top") scroll.scrollToTop();
  else
    scroller.scrollTo(name, {
      delay: 400,
      duration: 1000,
      smooth: true,
    });
};

export const MoveTo = (name) => {
  if (name === "top") scroll.scrollToTop();
  else
    scroller.scrollTo(name, {
      delay: 0,
      duration: 0,
      smooth: true,
    });
};
