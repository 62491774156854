import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import "./Dagboek.css";

const HTML = styled.html`
  ul {
    list-style-type: disc !important;
    marginleft: 20px !important;
    paddingleft: 20px !important;
  }

  a {
    color: blue;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0px;
    font-size: 18px;
    font-family: inter, sans-serif, arial, helvetica !important;
    color: #1175ba !important;
  }
  *[id] {
    scroll-margin-top: 50px;
  }
  *[title] {
    border-bottom: 1px dotted #888;
  }
  article {
    max-width: 768px;
    margin: 0 auto;
  }
  div#intro,
  div#vragen {
    /*	margin: -20px; */
    margin: 70px 0;
    padding: 20px;
    background: #eee;
    border: 1px solid #aaa;
    border-radius: 8px;
  }
  div#extra {
    margin: 60px 0;
  }
  div.terzij {
    margin: 10px 26px;
    font-size: 90%;
    color: #888;
  }
  h3 {
    margin: 2em 0 1.5em 0;
    font-size: 150%;
    font-weight: 600 !important;
  }
  h5 {
    margin: 0;
    font-size: 120%;
  }
  h6 {
    margin: 0;
    font-size: 150%;
    font-weight: 600 !important;
  }
  p,
  li,
  th,
  td {
    color: #448;
    line-height: 200%;
  }
  p:last-of-type,
  ul:last-child {
    margin-bottom: 0;
  }

  p {
    margin-top: 1.5rem !important;
  }
  blockquote {
    color: #666;
    line-height: 180%;
    padding: 0 5px 0 20px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    border: solid #b1b1b1;
    border-width: 0 0 0 5px;
    border-radius: 10px;
  }
  em {
    font-family: serif;
  }
  h3 em {
    font-weight: 600;
  }
  sup {
    vertical-align: super;
    line-height: 0.5em;
    font-size: 70%;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  img.rechts {
    float: right;
    margin-left: 10px;
  }
  iframe {
    margin-left: 6px;
  }

  table.vergelijk td {
    font-size: 80%;
    line-height: 1.1em;
    vertical-align: top;
  }

  table.overzicht {
    width: 100%;
    margin: 10px auto;
    border: 3px ridge #eee;
    border-collapse: collapse;
  }
  table.overzicht td {
    padding: 2px 8px;
    border: 1px solid #ccc;
  }
  table.overzicht tr {
    background: #eee;
  }
  table.overzicht tr:hover {
    background: #ffd;
  }
  table.overzicht tr.sjabbat {
    background: #fff;
  }
  td.sep {
    background: rgb(0, 157, 227, 0.3);
  }
  td.okt {
    background: rgb(0, 157, 227, 0.5);
  }
  td.elloel {
    background: rgb(0, 157, 227, 0.5);
  }
  td.tisjri {
    background: rgb(0, 157, 227, 0.7);
  }
  td.sep,
  td.okt,
  td.elloel,
  td.tisjri {
    text-align: right;
    border: none;
  }
  td.tab_l {
    border-left: none !important;
    padding-left: 0 !important;
  }
  td.tab_r {
    border-right: none !important;
    padding-right: 6px !important;
    text-align: right;
  }
`;

export const HtmlShower = (props) => {
  return (
    <HTML
      className="dagboekclass"
      dangerouslySetInnerHTML={{
        __html: props.html,
      }}
    />
  );
};
