import React, { useEffect, useRef, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectHighlight,
  Snippet,
  Highlight,
  Menu,
  Configure,
  RefinementList,
} from "react-instantsearch-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { Bug, Check, Upload } from "tabler-icons-react";
import "./ImageSearch.css";
import { showNotification, updateNotification } from "@mantine/notifications";
import { Badge, Tooltip } from "@mantine/core";

const NiceText = tw.p`mx-auto transition-all duration-500 group-hover:bg-gradient-to-bl  font-extrabold z-50 text-lg my-auto whitespace-normal w-64`;
const Article = tw.article`flex p-2 flex-col justify-between items-center gap-1 h-20 pb-2 w-64   cursor-pointer justify-between hover:shadow-xl transition-all duration-500 whitespace-normal bg-white rounded-xl shadow-md`;
const TempText = tw.p`mx-auto text-gray-500  w-64`;
const SpecialHighlight = tw(Highlight)`overflow-hidden px-1 -mt-8 w-64`;
const DropZone = tw.div`border-2 cursor-pointer rounded-md text-gray-700 flex-col bg-gray-100 h-32 transition-all duration-500 flex justify-center items-center text-center hover:bg-gray-300`;
const Input = tw.input`w-64 h-6 `;
const Line = tw.div`flex px-2 items-center flex-row my-1 gap-2 w-full`;
const TopZone = tw.div`border-b-2 mb-6`;
const TText = tw.p`text-gray-500 text-sm`;
const Title = tw.h3`font-bold h-16 text-lg -mb-8`;

export const NewStorageUpload = (props) => {
  const [searchClient, setSearchClient] = useState(
    algoliasearch("OEPYSXEN78", "a984df65a84848d4f43123ce9e8e094f")
  );
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const [writtenTags, setWrittenTags] = useState([]);
  const inputFile = useRef(null);

  const onKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      console.log("Tab");
      const tags = JSON.parse(JSON.stringify(writtenTags));
      tags.push(e.target.value.replace("  ", ""));
      setWrittenTags(tags);
      e.target.value = "";
    }
  };

  function dragOverHandler(ev) {
    console.log("File(s) in drop zone");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  const handleFileSelect = async (evt) => {
    let files = evt.target.files; // FileList object

    console.log(files);
    for (const file of files) {
      const base64n = await writebase64ToServer(file);
    }
  };

  const writebase64ToServer = async (file) => {
    showNotification({
      title: "Bestand wordt geupload",
      message: "Bestand wordt geupload 🖼️",
      loading: true,
      id: "storageupload",
      autoClose: false,
      disallowClose: true,
    });
    console.log("uploading", file);
    try {
      let reader = new FileReader();

      const formdata = new FormData();

      reader.onload = function () {
        formdata.append("file", file);
        formdata.append("filename", file.name);
        formdata.append("bucket", "cis");

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            file: reader.result.split(",").pop(),
            filename: file.name,
            bucket: "cis",
          }),
          files: file,
        };
        fetch(
          "https://cmscloudrun-earvvgo3hq-uc.a.run.app/upload_file",
          requestOptions
        )
          .then((response) => response.json())
          .then(async (response) => {
            console.log("result of uploading Bestand", response);
            const ad = await props.firestore
              .collection("storage")
              .add({
                name: file.name,
                size: file.size,
                type: file.type,
                url: response.body.filepath,
                tags: writtenTags,
                lastmodified: file.lastmodifiedDate || new Date(),
                date: new Date(),
              })
              .then(async () => {
                updateNotification({
                  id: "storageupload",
                  title: "Bestand geupload!",
                  message:
                    "Bestand is geupload, en kan even duren om in uploads the verschijnen!",
                  loading: true,
                  autoClose: false,
                  icon: <Check size={18} />,
                  color: "blue",
                  disallowClose: true,
                });

                await delay(6500);
                setSearchClient(
                  algoliasearch(
                    "OEPYSXEN78",
                    "a984df65a84848d4f43123ce9e8e094f"
                  )
                );
                searchClient.clearCache();
                updateNotification({
                  id: "storageupload",
                  title: "Bestand geupload en index bijgewerkt!",
                  message:
                    "Bestand is geupload, en zou bij geuploade bestanden moeten verschijnen!",
                  loading: false,
                  autoClose: 5000,
                  icon: <Check size={18} />,
                  color: "teal",
                  disallowClose: false,
                });
              });
          });
      };
      reader.readAsDataURL(file);
      //   };

      //   reader.onerror = function (error) {
      // updateNotification({
      //   id: "storageupload",
      //   title: "Bestand niet geupload!",
      //   message: "Er ging iets mis bij het uploaden van de Bestand 🖼️",
      //   loading: false,
      //   autoClose: 5000,
      //   icon: <Bug size={18} />,
      //   color: "red",
      //   disallowClose: false,
      // });
      // console.log("Error: ", error);
      //   };
      //   reader.readAsDataURL(file);
    } catch (error) {
      updateNotification({
        id: "storageupload",
        title: "Bestand niet geupload!",
        message: "Er ging iets mis bij het uploaden van de Bestand 🖼️",
        loading: false,
        autoClose: 5000,
        icon: <Bug size={18} />,
        color: "red",
        disallowClose: false,
      });
      console.log(error);
    }
  };

  const dropHandler = async (ev) => {
    console.log("File(s) dropped");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          var file = ev.dataTransfer.items[i].getAsFile();
          const base64n = await writebase64ToServer(file);

          console.log("... file[" + i + "].name = " + file.name);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        var file = ev.dataTransfer.items[i].getAsFile();
        const base64n = await writebase64ToServer(file);
        console.log(
          "... file[" + i + "].name = " + ev.dataTransfer.files[i].name
        );
      }
    }
  };

  function Hit({ hit }) {
    return hit.url ? (
      <Article
        className="group"
        onClick={() => {
          props.parentFunction({ url: hit.url, name: hit.name });
        }}
        key={hit.name}
      >
        <Tooltip
          label={hit.tags && hit.tags.map((tag) => tag + ", ")}
          withArrow
        >
          <div>
            <NiceText className="imgHit">
              <SpecialHighlight className="imgHit" attribute="name" hit={hit} />
            </NiceText>
            <TempText>
              <Highlight attribute="type" hit={hit} />
            </TempText>
            <TempText>
              <Highlight attribute="date" hit={hit} />
            </TempText>
          </div>
        </Tooltip>
      </Article>
    ) : (
      <></>
    );
  }

  return (
    <div key={props}>
      <TopZone>
        <Title>Voeg Bestand Toe</Title>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleFileSelect}
        />

        <DropZone
          id="drop_zone"
          onDrop={dropHandler}
          onDragOver={dragOverHandler}
          onClick={() => inputFile.current.click()}
        >
          <p>Drop files or click to upload...</p>
          <Upload color="gray"></Upload>
        </DropZone>
        <TText>Voeg tags toe om het bestand makkelijk terug te vinden.</TText>
        <Line>
          {writtenTags && writtenTags.map((tag) => <Badge>{tag}</Badge>)}

          <Input
            onKeyDown={onKeyDown}
            placeholder="Druk tab voor nog een tag"
          ></Input>
        </Line>
      </TopZone>
      <Title>Bestanden</Title>
      <InstantSearch searchClient={searchClient} indexName="storage">
        <SearchBox
          className="border-b text-lg"
          placeholder="Search for uploaded images..."
          autoFocus={true}
        />
        <Menu attribute="name" />

        <div className="overflow-y-scroll bg-white rounded-xl grid w-full">
          <Hits hitComponent={Hit} />
        </div>
      </InstantSearch>
    </div>
  );
};
