import React, { useEffect, useState } from "react";
import Logo from "images/logo.png";

import { Link as RouterLink } from "react-router-dom";
// import Search from "engine/Search";
import { Burger } from "@mantine/core";
import { useDisclosure, useScrollLock, useViewportSize } from "@mantine/hooks";
import { ScrollTo } from "engine/ScrollTo";
import { Search } from "tabler-icons-react";
import InstantSearchModal from "CMS/InstantSearchModal";

export const Navbar = (props) => {
  const { width, height } = useViewportSize();
  const [isMobile, setMobile] = useState(true);
  const [opened, { toggle }] = useDisclosure(false);
  const label = opened ? "Close navigation" : "Open navigation";
  const [scrollLocked, setScrollLocked] = useScrollLock();

  const [isOpenSearch, setIsOpenSearch] = useState(false);

  useEffect(() => {
    setMobile(width < 1024);
  }, [width]);

  return (
    <>
      <InstantSearchModal
        opened={isOpenSearch}
        onClose={() => {
          setIsOpenSearch(false);
        }}
      />
      <div className="lg:hidden absolute top-4 left-4 z-50 outline-none border-none bg-transparent backdrop-blur-md	 hocus:text-sky-300 transition-all duration-300">
        <Burger
          size={"lg"}
          opened={opened}
          onClick={() => {
            toggle();
            setScrollLocked(!scrollLocked);
          }}
          aria-label={label}
        ></Burger>
      </div>
      {(!isMobile || opened) && (
        <div
          className={`h-screen bg-white  lg:top-2 lg:pt-4 no-underline lg:bg-transparent font-bold lg:h-20 w-full ${
            props.absolute && `lg:absolute lg:pt-2`
          }`}
          absolute={props.absolute}
        >
          <div className="flex h-full transition-all text-gray-200 duration-200 no-underline flex-col lg:flex-row justify-between items-center  mx-auto px-4 sm:px-6 lg:px-8">
            <RouterLink className="text-black border-b-0" to="/">
              <img className="w-48 mr-3" src={Logo} alt="logo"></img>
            </RouterLink>
            <div className="group flex flex-col justify-center items-center lg:block">
              <RouterLink
                className={`hover:text-sky-500 ${
                  props.black ? `text-gray-900` : `text-gray-500 `
                }`}
                to={isMobile ? null : "/over-ons"}
              >
                Over ons
              </RouterLink>

              <div className="flex-col w-3/4  lg:m-0 lg:w-64 rounded-md shadow-md p-4 gap-4 hidden group-hover:flex absolute bg-slate-100 z-50">
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/over-ons/wat-is"}
                >
                  Wat is het CIS?
                </RouterLink>
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/over-ons/visie"}
                >
                  Visie
                </RouterLink>
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/over-ons/team"}
                >
                  Team
                </RouterLink>{" "}
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/over-ons/bestuur"}
                >
                  Bestuur
                </RouterLink>
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/over-ons/israelwerker"}
                >
                  Israëlwerker
                </RouterLink>
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/over-ons/lezingen"}
                >
                  Lezingen
                </RouterLink>
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/over-ons/partners"}
                >
                  Partners
                </RouterLink>
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/over-ons/meedoen"}
                >
                  Meedoen
                </RouterLink>
              </div>
            </div>
            <div className="group flex flex-col justify-center items-center lg:block">
              <RouterLink
                className={`hover:text-sky-500 ${
                  props.black ? `text-gray-900` : `text-gray-500 `
                }`}
                to={isMobile ? null : "/ontmoeten"}
              >
                Ontmoeten
              </RouterLink>
              <div className="flex-col w-3/4  lg:m-0 lg:w-64 rounded-md shadow-md p-4 gap-4 hidden group-hover:flex absolute bg-slate-100 z-50">
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/ontmoeten/dialoog"}
                >
                  Dialooggroepen
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/ontmoeten/synagogen"}
                >
                  Synagogen
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/ontmoeten/kerken"}
                >
                  Kerken
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/ontmoeten/organisaties"}
                >
                  Organisaties
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/ontmoeten/boeken"}
                >
                  Bibliotheken en boekwinkels
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/ontmoeten/kaart"}
                >
                  Kaart
                </RouterLink>
              </div>
            </div>
            <div className="group flex flex-col justify-center items-center lg:block">
              <RouterLink
                className={`hover:text-sky-500 ${
                  props.black ? `text-gray-900` : `text-gray-500 `
                }`}
                to={isMobile ? null : "/leren"}
              >
                Leren
              </RouterLink>
              <div className="flex-col w-3/4  lg:m-0 lg:w-64 rounded-md shadow-md p-4 gap-4 hidden group-hover:flex absolute bg-slate-100 z-50">
                {/* 
            <Link
              to={ "/leren/webinars"  
              }
            >
              Webinars
            </RouterLink>
           */}

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/artikelen"}
                >
                  Artikelen
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/columns"}
                >
                  Columns
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/boeken"}
                >
                  Boeken
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/videos"}
                >
                  Video's
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/jeugd"}
                >
                  Jeugd
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/israelzondag"}
                >
                  Israëlzondag
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/dagboek"}
                >
                  Dagboek bij de najaarsfeesten
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/pesach"}
                >
                  Sedermaaltijd Pesach
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/reiswijzer"}
                >
                  Reiswijzer
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/studiereizen"}
                >
                  Studiereizen
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/studiegroepen"}
                >
                  Studiegroepen
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/leren/onderwijs"}
                >
                  Onderwijsprojecten
                </RouterLink>
              </div>
            </div>
            <div className="group flex flex-col justify-center items-center lg:block">
              <RouterLink
                className={`hover:text-sky-500 ${
                  props.black ? `text-gray-900` : `text-gray-500 `
                }`}
                to={isMobile ? null : "/joodsebijbeluitleg"}
              >
                Joodse Bijbeluitleg
              </RouterLink>
              <div className="flex-col w-3/4  lg:m-0 lg:w-64 rounded-md shadow-md p-4 gap-4 hidden group-hover:flex absolute bg-slate-100 z-50">
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/joodsebijbeluitleg/jesaja"}
                >
                  Jesaja-project
                </RouterLink>
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/joodsebijbeluitleg/mattheus"}
                >
                  Mattheüs-project
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/joodsebijbeluitleg/parasja"}
                >
                  Parasja-project
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/joodsebijbeluitleg/cursus"}
                >
                  Cursus
                </RouterLink>
              </div>
            </div>
            <div className="group flex flex-col justify-center items-center lg:block">
              <RouterLink
                className={`hover:text-sky-500 ${
                  props.black ? `text-gray-900` : `text-gray-500 `
                }`}
                to={isMobile ? null : "/dienen"}
              >
                Dienen
              </RouterLink>
              <div className="flex-col w-3/4  lg:m-0 lg:w-64 rounded-md shadow-md p-4 gap-4 hidden group-hover:flex absolute bg-slate-100 z-50">
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/dienen/diavisie"}
                >
                  Diaconale visie
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/dienen/projecten"}
                >
                  Diaconale projecten
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/dienen/steunen"}
                >
                  Steun diaconale projecten
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/dienen/presentatie"}
                >
                  Presentatie
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/dienen/antisemitisme"}
                >
                  Opstaan tegen antisemitisme
                </RouterLink>
              </div>
            </div>
            <div className="group flex flex-col justify-center items-center lg:block">
              <RouterLink
                className={`hover:text-sky-500 ${
                  props.black ? `text-gray-900` : `text-gray-500 `
                }`}
                to={isMobile ? null : "/contact"}
              >
                Contact
              </RouterLink>
              <div className="flex-col w-3/4  lg:m-0 lg:w-64 rounded-md shadow-md p-4 gap-4 hidden group-hover:flex absolute bg-slate-100 z-50">
                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/contact/gegevens"}
                >
                  Gegevens
                </RouterLink>

                {/* 
            <Link
              to={ "/contact/vacature"  
              }
            >
              Vacatures
            </RouterLink>
           */}

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/contact/anbi"}
                >
                  ANBI
                </RouterLink>

                <RouterLink
                  className="text-gray-500 hover:text-sky-500"
                  to={"/contact/jaarverslagen"}
                >
                  Jaarverslagen
                </RouterLink>
              </div>
            </div>
            <div className="group flex flex-col justify-center items-center lg:block">
              <RouterLink
                className={`hover:text-sky-500 ${
                  props.black ? `text-gray-900` : `text-gray-500 `
                }`}
                to={{
                  pathname: "/nieuws",
                  state: {
                    scrollToSection: "nieuws",
                  },
                }}
                onClick={() => ScrollTo("nieuws")}
              >
                Nieuws
              </RouterLink>
            </div>
            <div className="group flex flex-col justify-center items-center lg:block">
              <div
                className="text-gray-500 cursor-pointer hover:text-sky-500 fill-gray-500 hover:fill-sky-500"
                onClick={() => {
                  setIsOpenSearch(true);
                }}
              >
                <Search></Search>
              </div>
            </div>
            <RouterLink
              className={`hover:text-sky-500 ${
                props.black ? `text-gray-900` : `text-gray-500 `
              }`}
              to="/english"
            >
              <div
                className="flex lg:mt-0 -mt-2 items-center justify-center"
                style={{ height: "32px", width: "50px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 60 30"
                  width="50"
                  height="20"
                >
                  <clipPath id="s">
                    <path d="M0,0 v30 h60 v-30 z" />
                  </clipPath>
                  <clipPath id="t">
                    <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
                  </clipPath>
                  <g clipPath="url(#s)">
                    <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
                    <path
                      d="M0,0 L60,30 M60,0 L0,30"
                      stroke="#fff"
                      strokeWidth="6"
                    />
                    <path
                      d="M0,0 L60,30 M60,0 L0,30"
                      clipPath="url(#t)"
                      stroke="#C8102E"
                      strokeWidth="4"
                    />
                    <path
                      d="M30,0 v30 M0,15 h60"
                      stroke="#fff"
                      strokeWidth="10"
                    />
                    <path
                      d="M30,0 v30 M0,15 h60"
                      stroke="#C8102E"
                      strokeWidth="6"
                    />
                  </g>
                </svg>

                {/* <svg xmlns="http://www.w3.org/2000/svg" width="110" height="80">
            <path d="M 0,0 H 110 V 80 H 0 Z" fill="#fff" />
            <path d="M 0,7.5 H 110 V 20 H 0 Z" fill="#0038b8" />
            <path d="M 0,60 H 110 V 72.5 H 0 Z" fill="#0038b8" />
            <path
              d="M 42.381566,47.285253 H 67.618435 L 55.000001,25.429492 Z m 12.618435,7.285255 12.61843,-21.855765 H 42.381566 Z"
              fill="none"
              stroke="#0038b8"
              stroke-width="2.75"
            />
          </svg> */}
              </div>
            </RouterLink>
          </div>
        </div>
      )}
    </>
  );
};
