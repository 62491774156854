// import { createClient } from "pexels";
import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
// import ImageUpload from "./ImageUpload";
// import DocUpload from "./DocUpload";
import {
  ActionIcon,
  Button,
  Image,
  Input,
  Modal,
  Pagination,
  Switch,
  Tabs,
  ThemeIcon,
} from "@mantine/core";
import {
  Check,
  CloudDataConnection,
  Discount,
  Dots,
  Link,
  PaperBag,
  Photo,
} from "tabler-icons-react";
import { NewImageUpload } from "./NewImageUpload";
import { StockPhoto } from "./StockPhoto";
import OldImageUpload from "./OldImageUpload";
import DocUpload from "engine/DocUpload";
import { NewStorageUpload } from "./NewStorageUpload";
// import { ImageUpload } from "./NewImageUpload";

const PickLine = tw.div`flex justify-between items-center`;

export const NewImagePicker = (props) => {
  // const client = createClient(
  //   "563492ad6f9170000100000116a956d7b7a74b3697eb610bffb52d7d"
  // );

  const [query, setQuery] = useState("");
  const [activePage, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [results, setResults] = useState();
  const [usePortrait, setUsePortrait] = useState(false);

  //Initialize the tabs we have and current active
  const tabsKeys = ["Stock foto's", "Afbeeldingsuploads", "Documenten"];
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  useEffect(() => {
    if (results) setTotalPages(Math.floor(results.total_results / 15));
  }, [results]);

  // useEffect(() => {
  //   if (results) Retrieve(query);
  // }, [activePage]);

  // function Retrieve(query) {
  //   client.photos
  //     .search({ query, per_page: 15, locale: "nl-NL", page: activePage })
  //     .then((photos) => {
  //       setResults(photos);
  //       console.log(results);
  //     });
  // }

  const [inputState, setInputState] = useState("");

  return (
    <Modal
      opened={props.opened}
      onClose={() => props.setOpened(false)}
      title="Kies een afbeelding"
      size="55%"
    >
      <div>
        <>
          <div>
            <Tabs>
              <Tabs.Tab label="Link" icon={<Link size={14} />}>
                <PickLine>
                  Via link
                  <Input
                    defaultValue={
                      props.defaultImg ? props.defaultImg : inputState
                    }
                    onChange={(e) => {
                      setInputState(e.target.value);
                      props.parentFunction(e.target.value);
                    }}
                    placeholder="Voeg een link in als afbeelding..."
                  ></Input>
                  {inputState != "" ||
                  (props.defaultImg && props.defaultImg !== "") ? (
                    <ActionIcon onClick={() => props.setOpened(false)}>
                      <Check></Check>
                    </ActionIcon>
                  ) : (
                    <ActionIcon>
                      <Dots></Dots>
                    </ActionIcon>
                  )}
                </PickLine>
                <Switch text="Portretmodus: " active={usePortrait} />
              </Tabs.Tab>
              <Tabs.Tab label="Stock foto's" icon={<Photo size={14} />}>
                <StockPhoto chooseMethod={props.parentFunction}></StockPhoto>
              </Tabs.Tab>
              <Tabs.Tab label="Uploads" icon={<PaperBag size={14} />}>
                <NewImageUpload
                  setState={props.setState}
                  parentFunction={props.parentFunction}
                  functions={props.functions}
                  firestore={props.firestore}
                ></NewImageUpload>
              </Tabs.Tab>
              <Tabs.Tab label="Uploads (Oud)" icon={<Discount size={14} />}>
                <OldImageUpload
                  metadataDoc={props.metadataDoc}
                  chooseMethod={props.parentFunction}
                  firebase={props.firebase}
                  firestore={props.firestore}
                  functions={props.functions}
                ></OldImageUpload>
              </Tabs.Tab>
              <Tabs.Tab label="Bestanden" icon={<PaperBag size={14} />}>
                <NewStorageUpload
                  setState={props.setState}
                  parentFunction={props.chooseMethodDoc}
                  functions={props.functions}
                  firestore={props.firestore}
                ></NewStorageUpload>
              </Tabs.Tab>
              <Tabs.Tab
                label="Bestanden (Oud)"
                icon={<CloudDataConnection size={14} />}
              >
                <DocUpload
                  metadataDoc={props.metadataDoc}
                  chooseMethod={(url) => {
                    props.chooseMethodDoc(url);
                  }}
                  firebase={props.firebase}
                  firestore={props.firestore}
                ></DocUpload>
              </Tabs.Tab>
            </Tabs>
          </div>
        </>
      </div>
    </Modal>
  );
};
