import React, { Component } from "react";
import tw from "twin.macro";
import styled from "styled-components";

//HTML editor
//__________________________________
import { ImagePicker } from "./ImagePicker";

//Import Export
//__________________________________

import { UploadContent, DownloadContent, RequestRedaction } from "./DataIO";

//Miscellaneous
//__________________________________
import { CanEditUID, CanPublish } from "engine/Permissions";
import { PrimaryButton as Base } from "components/misc/Buttons";

//CSS imports
//__________________________________
import "../../src/styles/customStyles.css";
import "./SearchCSS.css";

import { MetaData } from "./MetaData";
import { SunEditorComponent } from "./SunEditor";
import ProfileSection from "components/misc/ProfileSection";
import { NewImagePicker } from "./NewImagePicker/NewImagePicker";
import sanitizeHtml from "sanitize-html";
import { PureHTMLEditor } from "./PureHTMLEditor";
import { DateInput } from "@mantine/dates";

//Tailwind variables
//__________________________________

const PrimaryButton = tw(Base)`text-sm`;

const SaveButton = tw(
  PrimaryButton
)`ml-4 bg-green-400 text-green-100 hocus:bg-green-600`;

const BottomButtons = tw.div`flex justify-between justify-end mt-8`;

const TitleEdit = tw.input`border-2 rounded-md pl-2 h-14   text-black text-4xl tracking-wide`;
const TopSection = tw.div`flex justify-between align-middle items-center text-gray-900 mb-10 z-50`;

const DatePickWrapper = tw.div`border-2 rounded-md p-4 `;
const CalenderWrapper = tw.div`mx-auto flex justify-around mt-8`;

const Col = tw.div`flex flex-col gap-4`;

//Image plugin

export class AltEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    DownloadContent(this);
  }

  async reassignBatch(date) {
    const metadata = this.props.firestore.collection("metadata");
    const batchingDoc = await metadata.doc("batching").get();

    //Go from the newest to oldest (last to first) element in the date list. As soon as we encounter a date that is lower than this date we assign the corresponding batch to import PropTypes from 'prop-types'
    const dateList = batchingDoc.data().batchMinDates;
    for (var i = dateList.length - 1; i > -1; i--) {
      console.log(i);
      const minDate = dateList[i];
      if (minDate < date.getTime() / 1000) {
        this.setState({ batch: i });
        break;
      }
    }
  }

  render() {
    {
      console.log(
        "Editor changed",
        this.state,
        this.props.user,
        this.state.originalAuthor,
        this.props.userData
      );
    }
    //Load author of original article and make sure user is logged in
    if (this.props.user && this.state.originalAuthor !== undefined) {
      //Make sure the user is the author and may edit own posts or may edit posts of others
      if (this.props.userData) {
        return (
          <div>
            {this.state.openPicker && (
              <NewImagePicker
                parentFunction={(imgSrc) => {
                  this.setState({ iconSrc: imgSrc, openPicker: false });
                }}
                metadataDoc={this.props.metadataDoc}
                opened={this.state.openPicker}
                setOpened={(doc) => this.setState({ openPicker: false })}
                defaultImg={this.state.iconSrc}
                functions={this.props.functions}
                firestore={this.props.firestore}
                firebase={this.props.firebase}
              ></NewImagePicker>
              // old
              // <ImagePicker
              //   firestore={this.props.firestore}
              //   metadataDoc={this.props.metadataDoc}
              //   chooseMethod={(imgSrc) => {
              //     this.setState({ iconSrc: imgSrc, openPicker: false });
              //   }}
              //   closeMethod={() => this.setState({ openPicker: false })}
              //   firebase={this.props.firebase}
              // ></ImagePicker>
            )}
            <TopSection>
              <Col>
                <TitleEdit
                  onChange={(e) => {
                    this.setState({
                      title: e.target.value,
                      firstLetter: e.target.value.slice(0, 1).toLowerCase(),
                      secondLetter: e.target.value.slice(1, 2).toLowerCase(),
                    });
                  }}
                  placeholder="Titel van je artikel"
                  defaultValue={this.state.title && this.state.title}
                ></TitleEdit>
              </Col>
            </TopSection>
            {this.state.pureHtml ? (
              <PureHTMLEditor
                defaultValue={this.state.content}
                onChange={(e) => {
                  console.log("current value", e.target.value);
                  this.setState({
                    content: e.target.value,
                  });
                }}
              ></PureHTMLEditor>
            ) : (
              <SunEditorComponent
                metadataDoc={this.props.metadataDoc}
                functions={this.props.functions}
                defaultValue={this.state.content}
                firestore={this.props.firestore}
                parent={this}
                onOpenPicker={() => this.setState({ openPicker: true })}
                firebase={this.props.firebase}
                onChange={(value, core) => {
                  console.log("current value", value);
                  this.setState({
                    content: value,
                  });
                }}
                reloadMethod={async () => {
                  await UploadContent(
                    this,
                    this.state.visible ? this.state.visible : false,
                    true
                  );
                  console.log("reloaded");
                }}
              />
            )}
            {this.state.showAuthor && (
              <ProfileSection
                user={this.props.user}
                HTMLParent={this}
                metadataDoc={this.props.metadataDoc}
                mode={this.props.mode}
                useCustomAuthor={this.state.useCustomAuthor}
                name={this.state.authorName}
                bio={this.state.authorBio}
                photoUrl={this.state.authorImgUrl}
                site={this.state.authorSite}
                uid={this.state.authorUID}
                firestore={this.props.firestore}
                firebase={this.props.firebase}
                auth={this.props.auth}
              ></ProfileSection>
            )}
            <MetaData
              HTMLParent={this}
              metadataDoc={this.props.metadataDoc}
              userData={this.props.userData}
            />
            {this.state.categories.includes("Dagboek") ? (
              <DatePickWrapper>
                <p>
                  Kies een datum om de dagboekpost najaarsfeesten aan te
                  koppelen:
                </p>
                <CalenderWrapper>
                  {/* <StyledCalender
                    onChange={(value, event) =>
                      this.setState({ selectedDate: value })
                    }
                    value={this.state.selectedDate}
                    calenderType="Hebrew"
                    locale="he-IL"
                    minDate={new Date(2020, 8, 12)}
                    maxDate={new Date(2020, 9, 12)}
                  ></StyledCalender>
                  <StyledCalender
                    onChange={(value, event) =>
                      this.setState({ selectedDate: value })
                    }
                    value={this.state.selectedDate}
                    minDate={new Date(2020, 8, 12)}
                    maxDate={new Date(2020, 9, 12)}
                  ></StyledCalender> */}
                  <DateInput></DateInput>
                </CalenderWrapper>
              </DatePickWrapper>
            ) : (
              ""
            )}
            <BottomButtons>
              {!this.state.visible && (
                <PrimaryButton
                  onClick={() => UploadContent(this, false, "view")}
                >
                  Opslaan niet publiceren
                </PrimaryButton>
              )}
              {this.state.visible && (
                <PrimaryButton
                  onClick={() => UploadContent(this, true, "edit")}
                >
                  Tussentijds Opslaan
                </PrimaryButton>
              )}

              {CanPublish(this.props.userData, this.state.doc) ===
                "Can publish" && (
                <SaveButton
                  onClick={() => UploadContent(this, true, "dashboard")}
                >
                  Publiceer
                </SaveButton>
              )}
            </BottomButtons>
          </div>
        );
      } else
        return (
          <p>
            Je kunt dit artikel niet bewerken. Zorg dat je auteur bent van dit
            artikel en je eigen artikelen kunt bewerken of wanneer je niet de
            auteur van dit artikel bent dat je de rechten hebt om de artikelen
            van anderen te bewerken.
          </p>
        );
    } else
      return (
        <p>
          Je moet ingelogd zijn en artikelen moeten geladen zijn om artikelen te
          bewerken.
        </p>
      );
    return <p>Something went wrong</p>;
  }
}
