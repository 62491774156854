import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IKContext, IKImage } from "imagekitio-react";
import { useWindowScroll } from "@mantine/hooks";

const Wrapper = styled.div`
  ${tw`  w-screen left-0 z-0 grid gap-16 sm:gap-8 md:gap-16 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1  pointer-events-auto transition-all duration-500 `}
  z-index: 49;

  margin-top: -352px;
  @media (min-width: 768px) {
    margin-top: -352px;
  }
  @media (min-width: 1024px) {
    margin-top: -352px;
  }
`;

const CardHoverBox = styled.div`
  ${tw`lg:h-144 h-80 w-64  mx-auto flex mt-64 sm:mt-0 justify-center transition-all duration-500   cursor-pointer  z-50`}
  z-index:50;
`;

const Card = styled.div`
  ${tw`shadow-lg overflow-x-hidden overflow-y-hidden  transition-all w-64 duration-300 max-w-lg sm:mt-64 group-hover:mt-0 bg-gray-400  h-80 rounded-lg `}
`;

const CardTitle = styled.div((props) => [
  tw`h-16 bg-gray-100 rounded-t-lg text-center flex items-center justify-center z-50`,
  props.color == "blue" && tw`bg-sky-500 text-gray-200`,
  props.color == "gray" && tw`bg-gray-400`,
  props.color == "dark" && tw`bg-gray-300  text-gray-200`,
  "z-index:49",
]);

const CardLetters = styled.h5`
  ${tw`text-gray-100 text-xl font-semibold `}
`;

const CardImg = styled.div`
  ${tw`rounded-b-lg w-64 transition-all duration-1000 h-64 -z-50`} filter: blur(2px);
  z-index: -500;
`;

const Links = styled.div`
  ${tw`-mt-64 relative ml-0 text-gray-100 p-4 z-50 flex flex-col gap-2 text-gray-100 text-center font-semibold text-lg opacity-100 list-none`}
  z-index:600;
`;

const Root = tw.div`relative `;

const Item = tw.li` hover:text-gray-400`;
const StyledIK = styled(IKImage)`
  ${tw`object-cover`}
`;
export const Doelgroepen = () => {
  const [scroll, scrollTo] = useWindowScroll();
  {
  }
  return (
    <Root>
      <Wrapper>
        <CardHoverBox
          key={0}
          className={scroll.y < 40 && "group"}
          initial="rest"
          animate="rest"
        >
          <Card key={0}>
            <CardTitle color="blue">
              <CardLetters>Iedereen</CardLetters>
            </CardTitle>
            <CardImg>
              <IKContext urlEndpoint="https://ik.imagekit.io/cis">
                <StyledIK
                  path={`/jous.png`}
                  loading="lazy"
                  lqip={{ active: true }}
                  transformation={[
                    {
                      height: 300,
                      width: 260,
                      //cropMode: "extract",
                    },
                  ]}
                />
              </IKContext>
            </CardImg>
            <Links className="doelgroep">
              <Link to={"/leren/artikelen"}>
                <Item>Artikelen</Item>
              </Link>
              <Link to={"/leren/columns"}>
                <Item>Columns</Item>
              </Link>
              <Link to={"/leren/boeken"}>
                <Item>Boeken</Item>
              </Link>
              {/* <Link
                to={ "/leren/recensies"
                 },
                }
              >
                <Item>Recensies</Item>
              </Link> */}
              <Link to={"/joodsebijbeluitleg/parasja"}>
                <Item>Parasja-project</Item>
              </Link>
              <Link to={"/joodsebijbeluitleg/mattheus"}>
                <Item>Mattheüs-project</Item>
              </Link>
            </Links>
          </Card>
        </CardHoverBox>
        <CardHoverBox
          key={1}
          className={scroll.y < 40 && "group"}
          initial="rest"
          animate="rest"
        >
          <Card key={1}>
            <CardTitle color="gray">
              <CardLetters>Theologen</CardLetters>
            </CardTitle>
            <CardImg>
              <IKContext urlEndpoint="https://ik.imagekit.io/cis">
                <StyledIK
                  path={`/theologen.png`}
                  loading="lazy"
                  lqip={{ active: true }}
                  transformation={[
                    {
                      height: 300,
                      width: 260,
                      //cropMode: "extract",
                    },
                  ]}
                />
              </IKContext>
            </CardImg>
            <Links className="doelgroep">
              <Link to={"/leren/boeken"}>
                <Item>Boeken</Item>
              </Link>
              <Link to={"/leren/israelzondag"}>
                <Item>Israëlzondag</Item>
              </Link>

              <Link to={"/leren/studiegroepen"}>
                <Item>Studiegroepen</Item>
              </Link>
              <Link to={"/joodsebijbeluitleg/mattheus"}>
                <Item>Mattheüs-project</Item>
                {/* Test comment */}
              </Link>
            </Links>
          </Card>
        </CardHoverBox>
        <CardHoverBox
          key={2}
          className={scroll.y < 40 && "group"}
          initial="rest"
          animate="rest"
        >
          <Card key={2}>
            <CardTitle color="dark">
              <div className="text-gray-100 text-xl font-semibold">Jeugd</div>
            </CardTitle>
            <CardImg>
              <IKContext urlEndpoint="https://ik.imagekit.io/cis">
                <StyledIK
                  path={`/jeugd.jpg`}
                  loading="lazy"
                  lqip={{ active: true }}
                  transformation={[
                    {
                      height: 300,
                      width: 260,
                      //cropMode: "extract",
                    },
                  ]}
                />
              </IKContext>
            </CardImg>
            <Links className="doelgroep">
              <Link to={"/leren/videos"}>
                <Item>Video's</Item>
              </Link>
              <Link to={"/leren/jeugd"}>
                <Item>Jeugd</Item>
              </Link>
              <Link to={"/leren/onderwijs"}>
                <Item>Onderwijsprojecten</Item>
              </Link>
            </Links>
          </Card>
        </CardHoverBox>
        <CardHoverBox
          key={3}
          className={scroll.y < 40 && "group"}
          initial="rest"
          animate="rest"
        >
          <Card key={3}>
            <CardTitle>
              <div className="text-gray-800 text-xl font-semibold">
                Reizigers
              </div>
            </CardTitle>
            <CardImg>
              <IKContext urlEndpoint="https://ik.imagekit.io/cis">
                <StyledIK
                  path={`/reizigers.png`}
                  loading="lazy"
                  lqip={{ active: true }}
                  transformation={[
                    {
                      height: 300,
                      width: 260,
                      //cropMode: "extract",
                    },
                  ]}
                />
              </IKContext>
            </CardImg>
            <Links className="doelgroep">
              <Link to={"/leren/studiereizen"}>
                <Item>Studiereizen</Item>
              </Link>
              <Link to={"/leren/reiswijzer"}>
                <Item>Reiswijzer</Item>
              </Link>
              <Link to={"/ontmoeten/synagogen"}>
                <Item>Synagogen</Item>
              </Link>
              <Link to={"/ontmoeten/kerken"}>
                <Item>Kerken</Item>
              </Link>
              <Link to={"/ontmoeten/organisaties"}>
                <Item>Organisaties</Item>
              </Link>
              <Link to={"/ontmoeten/boeken"}>
                <Item>Boekwinkels en bibliotheken</Item>
              </Link>
            </Links>
          </Card>
        </CardHoverBox>
      </Wrapper>
    </Root>
  );
};
