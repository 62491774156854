import React from "react";
import tw from "twin.macro";
import Search from "engine/Search";
import styled from "styled-components";

import { Link } from "react-router-dom";
import useWindowDimensions from "engine/UseWindowDimensions.js";

const SubLinks = styled.ul((props) => [
  tw`
   hidden group-hocus:block transition-all duration-300 absolute pb-2 mt-2 z-50 bg-gray-100 rounded-lg
w-64`,
  props.isMobile && tw`w-10/12 absolute inset-x-0 mx-auto px-4 shadow-lg`,
]);

export const NavLink = styled(Link)`
  ${tw`whitespace-nowrap
  text-lg lg:text-sm lg:mx-4 lg:my-0
  font-semibold tracking-wide transition-all duration-300
  pb-1 border-b border-transparent hover:border-sky-500 `}
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded-md bg-sky-500 text-gray-100
  hocus:bg-sky-700 hocus:text-gray-200  
  border-b-0
`;

const SubLink = tw.li`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 
  font-semibold tracking-wide transition-all duration-300 pt-4 
  pb-1 border-b-2 border-transparent hover:border-gray-200 z-50 text-gray-700 list-none
`;

const MenuItemsWrapper = styled.span((props) => [tw`h-10 pb-2 mt-2 list-none`]);

const LangIcon = tw.svg`mt-4`;
export const NavLinksBlack = styled.div((props) => [
  props.isMobile &&
    tw`flex-col justify-between h-full py-8 -mt-16
  `,

  tw`flex text-gray-700 items-center `,
  props.noHeight && tw`h-12  justify-center flex`,
]);
// Kortere bovenkant: h-3/4 rounded-b-4xl

export function navLinks(isBlack = true) {
  const width = window.innerWidth;
  const isMobile = width < 1024;
  return [
    <NavLinksBlack key={1} isMobile={width < 1024}>
      <MenuItemsWrapper>
        <NavLink to={isMobile ? "" : "/over-ons"}>Over ons</NavLink>

        <SubLinks algemeen isMobile={width < 1024}>
          <SubLink>
            <Link
              to={{
                pathname: "/over-ons",
                state: {
                  scrollToSection: "wat-is",
                },
              }}
            >
              Wat is het CIS?
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/over-ons",
                state: {
                  scrollToSection: "visie",
                },
              }}
            >
              Visie
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/over-ons",
                state: {
                  scrollToSection: "team",
                },
              }}
            >
              Team
            </Link>
          </SubLink>{" "}
          <SubLink>
            <Link
              to={{
                pathname: "/over-ons",
                state: {
                  scrollToSection: "bestuur",
                },
              }}
            >
              Bestuur
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/over-ons",
                state: {
                  scrollToSection: "israelwerker",
                },
              }}
            >
              Israëlwerker
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/over-ons",
                state: {
                  scrollToSection: "lezingen",
                },
              }}
            >
              Lezingen
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/over-ons",
                state: {
                  scrollToSection: "partners",
                },
              }}
            >
              Partners
            </Link>
          </SubLink>
        </SubLinks>
      </MenuItemsWrapper>

      <MenuItemsWrapper className="group">
        <NavLink to={isMobile ? "" : "/ontmoeten"}>Ontmoeten</NavLink>
        <SubLinks isMobile={width < 1024}>
          <SubLink>
            <Link
              to={{
                pathname: "/ontmoeten",
                state: {
                  scrollToSection: "dialoog",
                },
              }}
            >
              Dialooggroepen
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/ontmoeten",
                state: {
                  scrollToSection: "synagogen",
                },
              }}
            >
              Synagogen
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/ontmoeten",
                state: {
                  scrollToSection: "kerken",
                },
              }}
            >
              Kerken
            </Link>
          </SubLink>

          <SubLink>
            <Link
              to={{
                pathname: "/ontmoeten",
                state: {
                  scrollToSection: "organisaties",
                },
              }}
            >
              Organisaties
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/ontmoeten",
                state: {
                  scrollToSection: "boeken",
                },
              }}
            >
              Bibliotheken en boekwinkels
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/ontmoeten",
                state: {
                  scrollToSection: "kaart",
                },
              }}
            >
              Kaart
            </Link>
          </SubLink>
        </SubLinks>
      </MenuItemsWrapper>
      <MenuItemsWrapper className="group">
        <NavLink to={isMobile ? "" : "/leren"}>Leren</NavLink>
        <SubLinks isMobile={width < 1024}>
          {/* <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "webinars",
                },
              }}
            >
              Webinars
            </Link>
          </SubLink> */}
          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "artikelen",
                },
              }}
            >
              Artikelen
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "columns",
                },
              }}
            >
              Columns
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "boeken",
                },
              }}
            >
              Boeken
            </Link>
          </SubLink>

          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "videos",
                },
              }}
            >
              Video's
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "jeugd",
                },
              }}
            >
              Jeugd
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "israelzondag",
                },
              }}
            >
              Israëlzondag
            </Link>
          </SubLink>

          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "dagboek",
                },
              }}
            >
              Dagboek bij de najaarsfeesten
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "pesach",
                },
              }}
            >
              Sedermaaltijd Pesach
            </Link>
          </SubLink>

          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "reiswijzer",
                },
              }}
            >
              Reiswijzer
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "studiereizen",
                },
              }}
            >
              Studiereizen
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "studiegroepen",
                },
              }}
            >
              Studiegroepen
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/leren",
                state: {
                  scrollToSection: "onderwijs",
                },
              }}
            >
              Onderwijsprojecten
            </Link>
          </SubLink>
        </SubLinks>
      </MenuItemsWrapper>
      <MenuItemsWrapper className="group">
        <NavLink to={isMobile ? "" : "/joodsebijbeluitleg"}>
          Joodse Bijbeluitleg
        </NavLink>
        <SubLinks isMobile={width < 1024}>
          <SubLink>
            <Link
              to={{
                pathname: "/jesaja",
                state: {
                  scrollToSection: "jesaja",
                },
              }}
            >
              Jesaja-project
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/joodsebijbeluitleg",
                state: {
                  scrollToSection: "mattheus",
                },
              }}
            >
              Mattheüs-project
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/joodsebijbeluitleg",
                state: {
                  scrollToSection: "parasja",
                },
              }}
            >
              Parasja-project
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/joodsebijbeluitleg",
                state: {
                  scrollToSection: "cursus",
                },
              }}
            >
              Cursus
            </Link>
          </SubLink>
        </SubLinks>
      </MenuItemsWrapper>
      <MenuItemsWrapper className="group">
        <NavLink to={isMobile ? "" : "/dienen"}>Dienen</NavLink>
        <SubLinks isMobile={width < 1024}>
          <SubLink>
            <Link
              to={{
                pathname: "/dienen",
                state: {
                  scrollToSection: "diavisie",
                },
              }}
            >
              Diaconale visie
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/dienen",
                state: {
                  scrollToSection: "projecten",
                },
              }}
            >
              Diaconale projecten
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/dienen",
                state: {
                  scrollToSection: "steunen",
                },
              }}
            >
              Steun diaconale projecten
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/dienen",
                state: {
                  scrollToSection: "presentatie",
                },
              }}
            >
              Presentatie
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/dienen",
                state: {
                  scrollToSection: "antisemitisme",
                },
              }}
            >
              Opstaan tegen antisemitisme
            </Link>
          </SubLink>
        </SubLinks>
      </MenuItemsWrapper>
      <MenuItemsWrapper className="group">
        <NavLink to={isMobile ? "" : "/contact"}>Contact</NavLink>
        <SubLinks isMobile={width < 1024}>
          <SubLink>
            <Link
              to={{
                pathname: "/contact",
                state: {
                  scrollToSection: "gegevens",
                },
              }}
            >
              Gegevens
            </Link>
          </SubLink>
          {/* <SubLink>
            <Link
              to={{
                pathname: "/contact",
                state: {
                  scrollToSection: "vacature",
                },
              }}
            >
              Vacatures
            </Link>
          </SubLink> */}
          <SubLink>
            <Link
              to={{
                pathname: "/contact",
                state: {
                  scrollToSection: "anbi",
                },
              }}
            >
              ANBI
            </Link>
          </SubLink>
          <SubLink>
            <Link
              to={{
                pathname: "/contact",
                state: {
                  scrollToSection: "jaarverslagen",
                },
              }}
            >
              Jaarverslagen
            </Link>
          </SubLink>
        </SubLinks>
      </MenuItemsWrapper>
      <MenuItemsWrapper className="group">
        <NavLink
          to={{
            pathname: "/",
            state: {
              scrollToSection: "nieuws",
            },
          }}
        >
          Nieuws
        </NavLink>
      </MenuItemsWrapper>
      <MenuItemsWrapper className="group">
        <Link to={isMobile && "search"}>
          <Search isMobile={width < 1024}></Search>
        </Link>
      </MenuItemsWrapper>
    </NavLinksBlack>,
    <NavLinksBlack key={2} noHeight={true}>
      <NavLink to="/english">
        <div style={{ height: "32px", width: "50px" }}>
          <LangIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60 30"
            width="50"
            height="20"
          >
            <clipPath id="s">
              <path d="M0,0 v30 h60 v-30 z" />
            </clipPath>
            <clipPath id="t">
              <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
            </clipPath>
            <g clipPath="url(#s)">
              <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
              <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
              <path
                d="M0,0 L60,30 M60,0 L0,30"
                clipPath="url(#t)"
                stroke="#C8102E"
                strokeWidth="4"
              />
              <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
              <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" strokeWidth="6" />
            </g>
          </LangIcon>

          {/* <svg xmlns="http://www.w3.org/2000/svg" width="110" height="80">
            <path d="M 0,0 H 110 V 80 H 0 Z" fill="#fff" />
            <path d="M 0,7.5 H 110 V 20 H 0 Z" fill="#0038b8" />
            <path d="M 0,60 H 110 V 72.5 H 0 Z" fill="#0038b8" />
            <path
              d="M 42.381566,47.285253 H 67.618435 L 55.000001,25.429492 Z m 12.618435,7.285255 12.61843,-21.855765 H 42.381566 Z"
              fill="none"
              stroke="#0038b8"
              stroke-width="2.75"
            />
          </svg> */}
        </div>
      </NavLink>
    </NavLinksBlack>,
  ];
}

export function navLinksEnglish(isBlack = true) {
  const width = window.innerWidth;

  return [
    <NavLinksBlack key={1} isMobile={width < 1024}></NavLinksBlack>,
    <NavLinksBlack key={2}>
      <NavLink to="/">
        <div style={{ height: "32px", width: "50px" }}>
          <LangIcon
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="20"
            viewBox="0 0 9 6"
          >
            <rect fill="#21468B" width="9" height="6" />
            <rect fill="#FFF" width="9" height="4" />
            <rect fill="#AE1C28" width="9" height="2" />
          </LangIcon>

          {/* <svg xmlns="http://www.w3.org/2000/svg" width="110" height="80">
            <path d="M 0,0 H 110 V 80 H 0 Z" fill="#fff" />
            <path d="M 0,7.5 H 110 V 20 H 0 Z" fill="#0038b8" />
            <path d="M 0,60 H 110 V 72.5 H 0 Z" fill="#0038b8" />
            <path
              d="M 42.381566,47.285253 H 67.618435 L 55.000001,25.429492 Z m 12.618435,7.285255 12.61843,-21.855765 H 42.381566 Z"
              fill="none"
              stroke="#0038b8"
              stroke-width="2.75"
            />
          </svg> */}
        </div>
      </NavLink>
    </NavLinksBlack>,
  ];
}
