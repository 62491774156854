import { Button } from "@mantine/core";
import React, { useState } from "react";
import tw from "twin.macro";

const Grid = tw.div`grid grid-cols-2 gap-2`;
const PrimaryButton = Button;
const Input = tw.input` w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border-2 border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white my-4 first:mt-0`;
const Wrap = tw.div` flex flex-row justify-center mt-6 transition-all duration-500`;
const Custom = tw.div`mt-6`;
export const Donation = (props) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(false);
  const [amount, setAmount] = useState("niet ingevuld");
  const links = {
    5: "",
    10: "",
    20: "",
    30: "Y",
    50: "",
    100: "",
  };

  async function OpenStripePortal(amount) {
    console.log(amount);
    var func = props.firebase.functions().httpsCallable("createStripeCheckout");
    func({ amount: amount })
      .then((response) => {
        window.location.replace(response.data.url);
      })
      .catch((error) => console.log(error));
  }

  return (
    <Wrap>
      {!open && !input && (
        <PrimaryButton
          size="lg"
          color="blue"
          className="bg-sky-500"
          onClick={() => setOpen(true)}
        >
          Steun
        </PrimaryButton>
      )}
      {open && (
        <Grid>
          {Object.keys(links).map((key, index) => (
            <PrimaryButton
              color="blue"
              className="bg-sky-500"
              key={key}
              onClick={() => OpenStripePortal(key)}
            >
              {key} {"€"}
            </PrimaryButton>
          ))}
          <Custom>
            <label>Aangepast bedrag:</label>
            <Input
              placeholder="Of vul hier uw specifieke bedrag in"
              onChange={(e) => setAmount(e.target.value)}
            ></Input>
            <PrimaryButton
              color="blue"
              className="bg-sky-500"
              key={999}
              onClick={() => OpenStripePortal(amount)}
            >
              {"Betaal aangepast bedrag: €"} {amount}
            </PrimaryButton>
          </Custom>
          {/* <PrimaryButton
            onClick={() => {
              setInput(true);
              setOpen(false);
            }}
          >
            Specifiek bedrag
          </PrimaryButton> */}
        </Grid>
      )}
    </Wrap>
  );
};
