import React, { useState, Suspence, lazy } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Suspense } from "react";

const SearchBox = lazy(() => import("./SearchBox"));

const MLNeg = tw.div`-mt-6`;

const Line = styled.span`
  ${tw`flex justify-center`}
`;
const Icon = styled.svg((props) => [
  tw`transition-all duration-300 border-b-2 border-transparent group-hover:border-sky-500 group-hocus:text-sky-300`,
  `flex: 1 0 auto`,
]);

const StyledText = styled.p((props) => [
  tw`whitespace-nowrap 
  text-lg lg:text-sm lg:mx-4 lg:my-0
  font-semibold tracking-wide transition-all duration-300
   border-b-2 border-transparent group-hover:border-sky-500 group-hocus:text-sky-300
`,
]);

const ClickToSearch = styled.button((props) => [
  `flex: 1 0 auto;
  margin-top:-2px;`,
  tw`lg:w-20  h-8 ml-2 bg-transparent border-none outline-none text-gray-700 flex items-center cursor-pointer`,
]);

function Search(props) {
  const [searching, setSearching] = useState(false);

  return (
    <div>
      {!searching ? (
        <ClickToSearch
          onClick={() => {
            // document.body.style.overflow = "hidden";
            // document.html.style.overflow = "hidden";
            setSearching(!searching);
            console.log("we are searching");
          }}
          isMobile={props.isMobile}
        >
          {props.isMobile ? (
            <MLNeg>
              <Line>
                <StyledText isMobile> </StyledText>
                <Icon
                  isMobile
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </Icon>
              </Line>
            </MLNeg>
          ) : (
            <Line className="group">
              <StyledText> </StyledText>
              <Icon
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </Icon>
            </Line>
          )}
        </ClickToSearch>
      ) : (
        <>
          <Suspense fallback={<div>Search</div>}>
            <SearchBox
              parentMethod={setSearching}
              isMobile={props.isMobile}
              setSearching={setSearching}
            ></SearchBox>
          </Suspense>
          {console.log("rendering searchbox")}
        </>
      )}
    </div>
  );
}

export default Search;
