import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { IKImage } from "imagekitio-react";

// Kortere bovenkant: h-3/4 rounded-b-4xl

const Root = styled.div`
  ${tw`opacity-100 w-screen relative -z-20 h-screen`}
`;

const Container = styled.div`
  ${tw`relative -mx-8 w-screen rounded-b-lg h-screen`}
  background: rgba(25, 25, 25, 0.0);
  margin-top: -100vh;
`;

const StyledIK = styled(IKImage)`
  ${tw`relative  -mx-8 -mt-8  w-screen rounded-b-lg object-cover`}
  height: 100vh;
`;

const HeroContainer = styled.div`
  ${tw`relative px-6 sm:px-8 mx-auto flex flex-col`}
  height: calc(100vh + 9rem);
`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-center   md:mt-0  text-black text-gray-100 leading-snug font-bold`}
  span {
    ${tw`inline-block mt-2`}
  }
  font-size: min(8vw, 8vh);
`;
const SubHeading = styled.p`
  ${tw`text-gray-100  `}
  font-size: min(6vw, 6vh);
`;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

const rand = getRandomInt(0, 5);
const urlPart =
  rand === 0
    ? "kinneret.webp"
    : rand === 1
    ? "jerusalem.webp"
    : rand === 2
    ? "lake.jpg"
    : "rock_edit.jpg";
export default () => {
  return (
    <>
      <Root>
        <meta httpEquiv="Accept-CH" content="DPR, Width" />

        <StyledIK path={`/${urlPart}`} loading="lazy" lqip={{ active: true }} />

        <Container>
          <HeroContainer>
            <Content>
              {/* // Low quality image placeholder and lazy loading original image
                in the background */}

              <Heading>Centrum voor Israëlstudies</Heading>
              <SubHeading>verbindt je met Israël</SubHeading>
              {/* <PrimaryAction>Eventuele Knop</PrimaryAction> */}
            </Content>
          </HeroContainer>
        </Container>
      </Root>
    </>
  );
};
