import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { useDocument } from "react-firebase-hooks/firestore";
import { SecondaryButton } from "./Buttons";
import { ImagePicker } from "engine/ImagePicker";
import { UploadContent } from "engine/DataIO";
import { NewImagePicker } from "engine/NewImagePicker/NewImagePicker";

const ProfileImg = tw.img`rounded-xl h-32 w-32 border-2 object-cover hover:cursor-pointer`;
const EditModeDiv = tw.div`flex sm:w-144 flex-col max-w-none`;

const Edit = tw(
  SecondaryButton
)`text-center text-blue-500 h-12 w-32 text-center mt-2`;
const ProfileWrapper = tw.div`p-4 border-2 rounded-xl mt-10 flex justify-between`;
const BioInput = tw.textarea`border-2 pl-2 rounded-md  text-gray-500 w-full text-sm`;
const NameInput = tw.input`text-xl font-bold border-2 pl-2 rounded-md`;
const HomePageInput = tw.input`pl-2 border-2 rounded-md mt-2 text-sm text-blue-300`;
const TextContainer = tw.div`flex flex-col align-middle my-auto sm:w-full md:w-full`;
const Bio = tw.p`mt-2 text-sm text-gray-500`;
const Name = tw.p`text-xl font-bold`;

const Link = tw.a`text-sm text-blue-300`;
const LogOut = tw(SecondaryButton)` mt-2 text-center  max-w-sm w-32 h-12`;
const RightCol = tw.div`ml-8`;

export default (props) => {
  const dbRef = props.firestore.collection("users");
  var query = null;
  if (props.profileUid) query = dbRef.doc(props.profileUid);
  else query = dbRef.doc("unknown_user");
  const [userDoc, loading, error] = useDocument(query);

  const [isEdit, setEdit] = useState(false);
  const [imagePickerOpen, setImagePicker] = useState(false);

  const [authorName, setAuthorName] = useState(
    props.useCustomAuthor
      ? props.name
      : userDoc && userDoc.exists && userDoc.data().displayName
  );
  const [bio, setBio] = useState(
    props.useCustomAuthor
      ? props.bio
      : userDoc && userDoc.exists && userDoc.data().bio
  );
  const [homepage, setHomepage] = useState(
    props.useCustomAuthor
      ? props.site
      : userDoc && userDoc.exists && userDoc.data().homepage
  );
  const [photoUrl, setPhotoUrl] = useState(
    props.useCustomAuthor ? props.photoUrl : userDoc && userDoc.data().photoUrl
  );

  async function changeEditMode() {
    //Save to user profile
    if (isEdit && props.profileUid && !props.useCustomAuthor) {
      setEdit(false);
      const data = {
        bio: bio,
        homepage: homepage,
      };

      const collectionRef = props.firestore.collection("users");
      const upload = collectionRef
        .doc(props.profileUid)
        .set(data, { merge: true });
    }

    //Else save the custom author to doc
    if (isEdit && props.useCustomAuthor) {
      setEdit(false);

      //Set the new author data to state and trigger reload since the values here come from post.data()
      props.HTMLParent.setState(
        {
          authorName: authorName || "",
          authorBio: bio || "",
          authorSite: homepage || "",
          authorPhotoUrl: photoUrl || "",
        },
        () => {
          UploadContent(props.HTMLParent, props.HTMLParent.visible, true);
          //Add the author to author map or overwrite it
          const authorsMap = props.metadataDoc.data().authors;
          authorsMap[authorName] = {
            name: authorName,
            description: bio,
            homepage: homepage,
            photoUrl: photoUrl,
          };
          props.firestore
            .collection("metadata")
            .doc("batching")
            .set({ authors: authorsMap }, { merge: true })
            .then((res) =>
              UploadContent(
                props.HTMLParent,
                props.HTMLParent.state.visible,
                "edit"
              )
            );
          //After the author is added to the map reload the page with the correct author in the costumauthor Field
        }
      );
    }

    //Enable edit mode
    if (!isEdit) {
      setEdit(true);
    }
  }

  useEffect(() => {
    if (userDoc)
      if (userDoc.exists) {
        if (userDoc.data().bio) setBio(userDoc.data().bio);
        else setBio("");
        if (userDoc.data().homepage != undefined)
          setHomepage(userDoc.data().homepage);
        else setHomepage("");
      }
  }, [userDoc]);

  function PickImageClosePicker(imgSrc) {
    setImagePicker(false);

    //set either the user picture
    if (props.profileUid) {
      const data = {
        photoUrl: imgSrc,
      };

      const collectionRef = props.firestore.collection("users");
      const upload = collectionRef
        .doc(props.profileUid)
        .set(data, { merge: true })
        .then((res) => setPhotoUrl(imgSrc));
    }

    //Or if using customauthor modify it directly on the doc
    else if (props.useCustomAuthor) {
      setPhotoUrl(imgSrc);
    }
  }
  return (
    <>
      {imagePickerOpen && (
        <NewImagePicker
          parentFunction={(imgSrc) => {
            PickImageClosePicker(imgSrc);
          }}
          metadataDoc={props.metadataDoc}
          opened={imagePickerOpen}
          setOpened={(doc) => setImagePicker(false)}
          defaultImg={
            props.profileUid
              ? userDoc && userDoc.data().photoUrl
              : props.photoUrl
          }
          functions={props.functions}
          firestore={props.firestore}
          firebase={props.firebase}
        ></NewImagePicker>
        // <ImagePicker
        //   firestore={props.firestore}
        //   metadataDoc={props.metadataDoc}
        //   chooseMethod={(imgSrc) => {
        //     PickImageClosePicker(imgSrc);
        //   }}
        //   closeMethod={() => setImagePicker(false)}
        //   firebase={props.firebase}
        // ></ImagePicker>
      )}

      <ProfileWrapper>
        {!isEdit && userDoc && props.profileUid && (
          <TextContainer>
            <Name>{userDoc.data().displayName}</Name>
            <Bio>{userDoc.data().bio}</Bio>
            <Link href={userDoc.data().homepage}>
              {userDoc.data().homepage}
            </Link>
          </TextContainer>
        )}
        {!isEdit && !props.profileUid && (
          <TextContainer>
            <Name>{props.name}</Name>
            <Bio>{props.bio}</Bio>
            <Link href={props.site}>{props.site}</Link>
          </TextContainer>
        )}
        {isEdit && (
          <EditModeDiv>
            <NameInput
              onChange={(e) => setAuthorName(e.target.value)}
              defaultValue={
                props.useCustomAuthor
                  ? props.name
                  : userDoc && userDoc.data().displayName
              }
              placeholder="Naam"
            ></NameInput>
            <BioInput
              onChange={(e) => setBio(e.target.value)}
              defaultValue={
                props.useCustomAuthor
                  ? props.bio
                  : userDoc && userDoc.data().bio
              }
              placeholder="Beschrijving (optioneel)"
            ></BioInput>
            <HomePageInput
              onChange={(e) => setHomepage(e.target.value)}
              defaultValue={
                props.useCustomAuthor
                  ? props.site
                  : userDoc && userDoc.data().homepage
              }
              placeholder="Website (optioneel)"
            ></HomePageInput>
          </EditModeDiv>
        )}
        <RightCol>
          <ProfileImg
            onClick={() => {
              if (
                props.user &&
                (props.user.uid == props.profileUid || props.useCustomAuthor) &&
                !props.noEdit
              )
                setImagePicker(true);
            }}
            src={
              props.profileUid
                ? userDoc && userDoc.data().photoUrl
                : props.photoUrl
            }
            alt={"Profielfoto"}
          ></ProfileImg>{" "}
          {props.user &&
            (props.user.uid == props.profileUid || props.useCustomAuthor) &&
            !props.noEdit &&
            !props.useCustomAuthor && (
              <Edit onClick={() => changeEditMode()}>
                {isEdit ? "Bewaar" : "Bewerk"}
              </Edit>
            )}
          {props.user &&
            userDoc &&
            userDoc.exists &&
            props.user.uid == userDoc.data().uid && (
              <LogOut onClick={() => props.auth.signOut()}>Log uit</LogOut>
            )}
        </RightCol>
      </ProfileWrapper>
    </>
  );

  return <div></div>;
};
