import React, { useEffect, Suspense, lazy } from "react";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { Doelgroepen } from "components/hero/Doelgroepen";
import { StyledDiv } from "components/misc/Layouts";
import { Element } from "react-scroll";
import { useLocation, useParams } from "react-router";
import { MoveTo, ScrollTo } from "engine/ScrollTo";
import styled from "styled-components";
import tw from "twin.macro";
import { SimpleHeading } from "contentblocks/SimpleHeading";
import { Helmet } from "react-helmet";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import nlLocale from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
// import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import NewsHighlight from "components/blogs/NewsHighlight";
import { renderEventContent } from "contentblocks/DagboekNajaarsfeesten";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "engine/UseWindowDimensions";
import { Navbar } from "NewNavbar";

const Material = lazy(() => import("components/blogs/Material"));
// const Calendar = lazy(() => import("@ericz1803/react-google-calendar"));
const LargeVacuum = tw.div`h-64`;
const CalendarWrap = styled.div`
  max-width: 1400px;
  ${tw`mx-auto`}
`;

export default (props) => {
  const location = useLocation();
  const { section } = useParams();

  useEffect(() => {
    if (location.state) ScrollTo(location.state.scrollToSection);
    else {
      if (section) {
        console.log("scrolling to", section);
        ScrollTo(section);
      }
    }
  }, [location, section]);

  // useEffect(() => {
  //   const script = document.createElement("script");

  //   script.src = "https://bible-link.globalrize.org/plugin.js";
  //   script.async = true;
  //   script.defer = true;
  //   script.language = "nl";
  //   script.translation = "hsv";

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  const history = useNavigate();

  const { width, height } = useWindowDimensions();

  return (
    <>
      <Helmet>
        <title>Centrum voor Israëlstudies</title>
        <meta
          name="description"
          content="Welkom bij het centrum voor Israëlstudies."
        />
        <script
          async
          defer
          src="https://bible-link.globalrize.org/plugin.js"
          data-language="nl"
          data-translation="hsv"
        ></script>
      </Helmet>

      <Navbar
        absolute
        setInstantSearchOpened={props.setInstantSearchOpened}
      ></Navbar>
      <Element name="top">
        <StyledDiv>
          <NewsHighlight
            // Not all of these props necessary, quick solution
            maxBatchDoc={props.metadata && props.metadata}
            initialTab={4}
            user={props.user}
            firestore={props.firestore}
            auth={props.auth}
            useCollectionData={props.useCollectionData}
            hideTabs={true}
            loadMaterial={true}
            space
            scrollTo={() => MoveTo(section)}
          ></NewsHighlight>
          {/* <StyledHeader links={navLinks(true)} /> */}
          <Hero />
        </StyledDiv>
      </Element>
      <Doelgroepen />
      <LargeVacuum></LargeVacuum>
      <StyledDiv>
        <Suspense fallback={<div>Loading...</div>}>
          <Element name="nieuws">
            <Material
              metadata={props.metadata && props.metadata}
              initialTab={4}
              category="Nieuws"
              user={props.user}
              firestore={props.firestore}
              auth={props.auth}
              useCollectionData={props.useCollectionData}
              hideTabs={true}
              loadMaterial={true}
              space
              scrollTo={() => MoveTo(section)}
            />
          </Element>
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <SimpleHeading heading="Kalender"></SimpleHeading>

          <FullCalendar
            plugins={[dayGridPlugin, googleCalendarPlugin, listPlugin]}
            initialView={width > 768 ? "dayGridMonth" : "timeGridFourDay"}
            views={{
              timeGridFourDay: {
                type: "list",
                dayCount: 7,
              },
            }}
            eventOrder={"start"}
            locales={[nlLocale]}
            googleCalendarApiKey="AIzaSyApgQvDLkm4bP8LZvhGdVrUCQWhW6IQ-Kk"
            locale="nl"
            eventContent={(i) => renderEventContent(i, history)}
            eventSources={[
              {
                googleCalendarId:
                  "9ub3e2c0v093oug40dbct09dggmlpdmj@import.calendar.google.com",
              },
              {
                googleCalendarId:
                  "nl.jewish#holiday@group.v.calendar.google.com",
              },
              {
                googleCalendarId:
                  "kcia9kvjvqfpm8r2327olt8qok@group.calendar.google.com",
              },
              {
                googleCalendarId:
                  "v6ml1i6os9pi9s6jthmmlpo4is@group.calendar.google.com",
              },
              { googleCalendarId: "centrumvoorisraelstudies@gmail.com" },
              {
                events:
                  props.metadata &&
                  props.metadata.parasjotData &&
                  Object.values(props.metadata.parasjotData),
              },
              {
                events:
                  props.eventsWithUrls &&
                  Object.values(props.eventsWithUrls.eventsWithUrls),
              },
            ]}
          />
          {console.log(props.eventsWithUrls)}
        </Suspense>
        {/* <Suspense fallback={<div>Loading...</div>}>
          <ContactUsForm />
        </Suspense> */}
        <Footer
          firestore={props.firestore}
          functions={props.functions}
          firebase={props.firebase}
        />
      </StyledDiv>
    </>
  );
};
