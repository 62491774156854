import React from "react";
import {
  SectionHeading,
  MainHeading as Main,
} from "components/misc/Headings.js";

import tw from "twin.macro";
import { SectionLine } from "components/misc/Buttons";

const Heading = tw(
  SectionHeading
)`mt-32  text-black text-left text-3xl sm:text-4xl lg:text-5xl text-center mb-8 leading-tight`;

const Blue = tw.span`text-sky-300`;

export const SimpleHeading = (props) => {
  return (
    <div>
      <Heading>
        {props.heading}
        <Blue>{props.heading2}</Blue>
      </Heading>
    </div>
  );
};

export const MainHeading = (props) => {
  return (
    <div>
      <Main>
        {props.heading}
        <Blue>{props.heading2}</Blue>
      </Main>
    </div>
  );
};
