import React, { useEffect, useState } from "react";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { Donation } from "./Donation";
import { IKImage } from "imagekitio-react";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { FaCheck, FaEdit, FaRegSave } from "react-icons/fa";

const SectionComponent = ({
  subheading = null,
  subheading2 = null,
  heading = null,
  description = null,
  description2 = null,
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = null,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  smallerBottom = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
  listItems = null,
  subdescription = null,
  subdescription2 = null,
  listItems2 = null,
  donation = false,
  smallImg = false,
  lessMargin = false,
  useNumbersList = false,
  firestore = null,
  firebase = null,
  centerSubDescription = false,
  addendum = null,
  verySmallImg = false,
  bottom = null,
  useExternalImg = false,
  wideBottom = false,
  user = null,
}) => {
  const [firestoreDoc, setFirestoreDoc] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editableContent, setEditableContent] = useState({});

  useEffect(() => {
    if (firestore && heading) {
      const docRef = doc(firestore, "web_sections", heading);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          setFirestoreDoc(docSnap.data());
          setEditableContent(docSnap.data());
        }
      });
    }
  }, [firestore, heading]);

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleChange = (key, value) => {
    setEditableContent({ ...editableContent, [key]: value });
  };

  const handleSave = () => {
    const docRef = doc(collection(firestore, "web_sections"), heading);
    setDoc(docRef, editableContent).then(() => {
      setFirestoreDoc(editableContent);
      setEditMode(false);
    });
  };

  const renderTextElement = (key, defaultValue, className) => {
    const isPlainText = typeof defaultValue === "string";
    const content = editableContent[key] || defaultValue;

    return editMode && isPlainText ? (
      <textarea
        className={
          className +
          " w-full border border-dashed border-2" +
          (content ? "h-full" : "h-8")
        }
        value={content}
        onChange={(e) => handleChange(key, e.target.value)}
      />
    ) : (
      <div className={className}>
        {content && content.split
          ? content.split("\n").map((paragraph) => (
              <>
                {paragraph}
                <br />
              </>
            ))
          : content}
      </div>
    );
  };

  useEffect(() => {
    if (firestore && heading) {
      const docRef = doc(firestore, "web_sections", heading);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          setFirestoreDoc(docSnap.data());
        }
      });
    }
  }, [firestore, heading]);

  // Helper function to get the value from firestore doc or fallback to default
  const getValue = (key, defaultValue) => {
    return firestoreDoc[key] || defaultValue;
  };

  return (
    <div className={`relative mt-32 mx-auto ${lessMargin ? "mt-6" : ""}`}>
      <div
        className={`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center ${
          imageSrc ? "" : "md:w-full"
        }`}
      >
        {imageSrc && (
          <div
            className={`w-full max-w-md mx-auto md:max-w-none md:mx-0 md:w-1/2 ${
              smallImg ? "md:w-4/12" : ""
            } ${verySmallImg ? "md:w-3/12" : ""} relative`}
          >
            {!useExternalImg ? (
              <IKImage
                path={`/${imageSrc}`}
                loading="lazy"
                lqip={{ active: true }}
                width={640}
                className={`w-full object-cover rounded-md ${
                  imageBorder ? "border-2" : ""
                } ${imageShadow ? "shadow-md" : ""}`}
              />
            ) : (
              <img
                src={imageSrc}
                className={`object-cover ${imageRounded ? "rounded-md" : ""} ${
                  imageBorder ? "border-2" : ""
                } ${imageShadow ? "shadow-md" : ""}`}
              />
            )}
            {imageDecoratorBlob && (
              <SvgDotPattern className="w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-sky-300 -z-10" />
            )}
          </div>
        )}
        <div
          className={`w-full max-w-md mx-auto md:max-w-none md:mx-0 ${
            imageSrc && "md:w-1/2"
          } mt-0 pt-0 ${
            imageSrc && textOnLeft
              ? "md:mr-12 lg:mr-16 md:order-first"
              : "md:ml-12 lg:ml-16 md:order-last"
          } ${smallImg ? "" : ""}`}
        >
          <div
            className={`text-left px-4 font-normal ${
              centerSubDescription ? "flex justify-center items-center" : ""
            }`}
          >
            <div className="flex items-center gap-2">
              {user && (
                <div
                  onClick={() => {
                    if (editMode) handleSave();
                    setEditMode(!editMode);
                  }}
                  className="h-6 w-6 shrink-0 grow-0 rounded bg-sky-500 inline-flex items-center justify-center text-white cursor-pointer hover:bg-sky-700 transition-all duration-200"
                >
                  {editMode ? <FaRegSave /> : <FaEdit />}
                </div>
              )}
              {renderTextElement(
                "heading",
                heading,
                "text-sky-500 font-bold mb-2 text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight"
              )}
            </div>
            {renderTextElement(
              "subheading",
              subheading,
              "text-center md:text-left text-xl md:text-2xl mt-8 md:mt-0"
            )}
            {renderTextElement(
              "description",
              description,
              "mt-2 text-left md:text-base lg:text-lg font-normal leading-relaxed text-gray-600 max-w-3xl"
            )}
            {renderTextElement(
              "subdescription",
              subdescription,
              "list-disc mt-2 text-left md:text-base lg:text-lg font-normal leading-relaxed text-gray-400 max-w-3xl"
            )}
            {getValue("listItems", listItems) &&
              (!useNumbersList ? (
                <ul className="list-disc p-2 text-left md:text-base lg:text-lg font-normal leading-relaxed text-gray-400">
                  {getValue("listItems", listItems).map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              ) : (
                <ol className="list-decimal p-2 text-left md:text-base lg:text-lg font-normal leading-relaxed text-gray-400">
                  {getValue("listItems", listItems).map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ol>
              ))}
            {getValue("subheading2", subheading2) && (
              <>
                <br />
                <SubheadingBase className="text-center md:text-left text-xl md:text-2xl mt-8 md:mt-0">
                  {getValue("subheading2", subheading2)}
                </SubheadingBase>
              </>
            )}
            {getValue("description2", description2) &&
              getValue("description2", description2).map((item) => (
                <p
                  className="mt-2 text-left md:text-base lg:text-lg font-normal leading-relaxed text-gray-600 max-w-3xl"
                  key={item}
                >
                  {item}
                </p>
              ))}
            {getValue("subdescription2", subdescription2) && (
              <span
                className={`list-disc mt-2 text-left md:text-base lg:text-lg font-normal leading-relaxed text-gray-400 max-w-3xl ${
                  centerSubDescription ? "mx-auto flex justify-center" : ""
                }`}
              >
                {getValue("subdescription2", subdescription2)}
              </span>
            )}
            {getValue("listItems2", listItems2) &&
              (!useNumbersList ? (
                <ul className="list-disc p-2 text-left md:text-base lg:text-lg font-normal leading-relaxed text-gray-400">
                  {getValue("listItems2", listItems2).map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              ) : (
                <ol className="list-decimal p-2 text-left md:text-base lg:text-lg font-normal leading-relaxed text-gray-400">
                  {getValue("listItems2", listItems2).map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ol>
              ))}
            {/* {donation && (
              <Donation firestore={firestore} firebase={firebase}></Donation>
            )} */}
            {getValue("addendum", addendum) && (
              <span
                className={`list-disc mt-2 text-left md:text-base lg:text-lg font-normal leading-relaxed text-gray-400 max-w-3xl ${
                  centerSubDescription ? "mx-auto flex justify-center" : ""
                }`}
              >
                {getValue("addendum", addendum)}
              </span>
            )}
          </div>
        </div>
      </div>
      {getValue("bottom", bottom) && (
        <div className="text-left px-4 font-normal">
          <br />
          <span
            className={`list-disc mt-2 text-left md:text-base lg:text-lg font-normal leading-relaxed text-gray-400 max-w-3xl ${
              centerSubDescription ? "mx-auto flex justify-center" : ""
            } ${wideBottom ? "max-w-6xl" : ""}`}
          >
            {getValue("bottom", bottom)}
          </span>
        </div>
      )}
    </div>
  );
};

export default SectionComponent;
