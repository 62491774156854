import React from "react";
import { CanEdit, CanDelete } from "engine/Permissions";
import { SecondaryButton } from "components/misc/Buttons";
import tw from "twin.macro";

const Hoder = tw.div`flex flex-col md:flex-row`;
const SecondMod = tw(SecondaryButton)`w-48`;
const DeleteButtonForm = tw(
  SecondaryButton
)`md:ml-4 bg-red-400 w-48 text-gray-100 hocus:bg-red-600`;

function EditButton(props) {
  if (props.user)
    return <SecondMod onClick={props.editClick}>Bewerk artikel</SecondMod>;
}

function DeleteButton(props) {
  switch (CanDelete(props.userData, props.article)) {
    case "Can delete":
      return (
        <DeleteButtonForm onClick={props.deleteClick}>
          Verwijder artikel
        </DeleteButtonForm>
      );
  }
}

export default (props) => {
  return (
    <Hoder>
      {EditButton(props)}
      {DeleteButton(props)}
    </Hoder>
  );
};
