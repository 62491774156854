export function CanEdit(userDoc, articleDoc) {
  if (userDoc) {
    if (articleDoc) {
      if (userDoc.exists) {
        if (articleDoc.exists) {
          if (userDoc.data().uid === articleDoc.data().authorUID) {
            if (userDoc.data().rights.includes("Eigen posts wijzigen")) {
              return "Can edit";
            }
            //return "Author but no permissions";
          }
          if (userDoc.data().rights.includes("Posts van anderen wijzigen")) {
            return "Can edit";
          }
          return "Not author and no external edit rights";
        }
        return "Article data not found";
      }
      return "User data not found";
    }
    return "No article data";
  }
  return "No user data";
}

export function CanEditUID(userDoc, uid) {
  if (userDoc) {
    if (userDoc.exists) {
      if (userDoc.data().rights.includes("Eigen posts wijzigen")) {
        return "Can edit";
      }
      //return "Author but no permissions";

      if (userDoc.data().rights.includes("Posts van anderen wijzigen")) {
        return "Can edit";
      }
      return "Not author and no external edit rights";

      return "Article data not found";
    }
    return "User data not found";
  }
  return "No user data";
}

export function CanDelete(userDoc, articleDoc) {
  if (userDoc) {
    if (articleDoc) {
      if (userDoc.exists) {
        if (articleDoc.exists) {
          if (userDoc.data().uid === articleDoc.data().authorUID) {
            if (userDoc.data().rights.includes("Eigen posts verwijderen")) {
              return "Can delete";
            }
            return "Author but no permissions";
          }
          if (userDoc.data().rights.includes("Posts van anderen verwijderen")) {
            return "Can delete";
          }
          return "Not author and no external edit rights";
        }
        return "Article data not found";
      }
      return "User data not found";
    }
    return "No article data";
  }
  return "No user data";
}

export function CanPublish(userDoc, articleDoc) {
  if (userDoc) {
    if (articleDoc) {
      if (userDoc.exists) {
        if (articleDoc.exists) {
          if (userDoc.data().rights.includes("Posts redacteren")) {
            return "Can publish";
          }
          //Not the author
          if (userDoc.data().uid !== articleDoc.data().authorUID) {
            if (userDoc.data().rights.includes("Posts van anderen wijzigen"))
              return "Can publish";
          }
          //actually the original author
          else {
            if (
              userDoc.data().rights.includes("Posts maken (zonder redactie)")
            ) {
              return "Can publish";
            }
            if (userDoc.data().rights.includes("Posts maken (geredacteerd)")) {
              return "Can publish";
            }
          }
          return "Not author and no external edit rights";
        }
        return "Article data not found";
      }
      return "User data not found";
    }
    return "No article data";
  }
  return "No user data";
}
