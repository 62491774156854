import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Base = styled.div`
  ${tw`relative inline-block w-10 mr-2 align-middle select-none transition-all duration-200 ease-in`}
  ${(props) => props.active && tw`border-blue-400  `}
`;
const Input = styled.input`
  ${tw`absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`} ${(
    props
  ) => props.active && tw`ml-4 `}
`;
const Label = styled.label`
  ${tw` block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer`}${(
    props
  ) => props.active && tw`bg-blue-400`}
  ${(props) => props.disabled && tw`bg-gray-100`}
`;

const Text = styled.label`
  ${tw`mr-2 mt-4`}${(props) => props.disabled && tw`text-gray-400`}
`;

const Root = styled.span`
  display: block;
  ${(props) =>
    props.fixWidth &&
    `display:flex; justify-content:space-between; width:360px; align-items:center;`}
`;

export const Toggle = (props) => {
  return (
    <Root
      fixWidth={props.fixWidth}
      data-tip={props.dataTip}
      data-for={props.dataFor}
    >
      {props.fixWidth ? (
        <div>
          <Text disabled={props.disabled}>{props.text}</Text>
        </div>
      ) : (
        <Text disabled={props.disabled}>{props.text}</Text>
      )}
      <Base
        onClick={!props.disabled && props.parentMethod}
        active={props.active}
      >
        <Input
          disabled={props.disabled}
          active={props.active}
          type="checkbox"
          name="toggle"
          id="toggle"
        ></Input>
        <Label active={props.active}></Label>
      </Base>
    </Root>
  );
};
