import React, { useRef, useState } from "react";
import tw from "twin.macro";
import { ReactComponent as EmailNewsletterIconBase } from "../../images/email-newsletter-icon.svg";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton } from "components/misc/Buttons.js";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Component } from "react";
import { doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Loader } from "@mantine/core";

const Container = tw(ContainerBase)` flex flex-col`;

const Description = tw.span`mt-4 max-w-xs font-medium text-sm mx-auto `;

const Form = tw.div`text-sm mt-6 flex h-12 items-center mx-auto w-full`;
const Input = tw.input` h-12 w-3/4 border-none px-6 py-2 rounded-md bg-gray-600 tracking-wider font-bold  outline-none sm:rounded-r-none hover:bg-gray-800 transition-all duration-300 text-gray-200`;
const Button = tw(
  PrimaryButton
)`px-2 sm:rounded-l-none flex items-center text-center bg-sky-500 text-gray-100 hocus:bg-sky-700 hocus:text-gray-300 border-2 border-sky-500 hocus:border-sky-700 h-12`;

const CategoryGrid = tw.div`mt-4 rounded-lg sm:grid-cols-2  `;
const CatButton = tw(
  PrimaryButton
)`text-sm  px-0 rounded-md flex gap-3 justify-start items-center pl-3 w-full bg-transparent text-gray-700   border-2 hocus:bg-transparent hocus:text-gray-700 hocus:border-gray-400`;
const CatButtonSelected = tw(
  PrimaryButton
)`text-sm  flex gap-4 truncate pl-3 rounded-md w-full justify-start  bg-transparent text-gray-700 border-2 hocus:bg-transparent hocus:text-gray-700 hocus:border-gray-400`;
const CheckBox = tw.input`h-6 w-6 mr-2`;

export default (props) => {
  //props.firebase.functions().useFunctionsEmulator("http://localhost:5001");

  const [mail, setMail] = useState("");

  var query = null;
  if (props.firestore) query = doc(props.firestore, "metadata", "mailchimp");
  const [mailchimpDoc] = useDocumentData(query);
  const [toggles, setToggles] = useState();
  const [activeToggles, setActiveToggles] = useState();
  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(false);

  async function AddUserToCampaigns() {
    const email = mail ? mail.toLowerCase() : "";
    if (email.includes(".") && email.includes("@")) {
      setLoading(true);
      const ids = [];
      console.log("email", email);

      //Add all ids of toggles that are true to the list
      for (var x = 0; x < toggles.length; x++) {
        const tog = toggles[x];
        if (tog.ref.current) {
          if (tog.ref.current.state.active) {
            ids.push(tog.id);
          }
        }
      }

      if (props.hideToggles) ids.push(toggles[props.toggleIndex].id);

      var addUser = httpsCallable(props.functions, "AddUserToMailchimp");

      addUser({ email: email, audiences: ids })
        .then((result) => {
          // Read result of the Cloud Function.
          console.log(result);
          setDetail(result["data"]);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  if (!activeToggles && mailchimpDoc) {
    setActiveToggles(Array(mailchimpDoc.categories.length).fill(true));
  }

  if (!toggles && mailchimpDoc) {
    const temp = [];
    for (var x = 0; x < mailchimpDoc.categories.length; x++) {
      const cat = mailchimpDoc.categories[x];
      const ref = React.createRef();
      temp.push({
        text: cat.name,
        id: cat.id,
        numMails: cat.numMails,
        active: props.filter
          ? props.filter.includes(cat.name)
          : !props.negFilter?.includes(cat.name),
        nr: x,
        ref: ref,
      });
    }
    setToggles(temp);
  }
  return (
    <Container>
      {mailchimpDoc && (
        <>
          {!props.hideInfo && (
            <Description>
              Schrijf je in voor mails van het CIS. Nieuwsbrieven, joodse
              exegese of andere hieronder aangegeven categorieën.
            </Description>
          )}
          <Form>
            <Input
              name="newsletter"
              type="email"
              placeholder="Jouw e-mailadres"
              onChange={(e) => {
                setMail(e.target.value);
              }}
            />
            <Button
              onClick={() => {
                AddUserToCampaigns();
              }}
            >
              {loading && <Loader />}
              Schrijf in
            </Button>
          </Form>
          <CategoryGrid>
            {!props.hideToggles &&
              !detail &&
              toggles &&
              toggles.map(
                (toggle, index) =>
                  toggle.active && (
                    <Toggle
                      key={index}
                      text={
                        props.customTexts
                          ? props.customTexts[index]
                          : toggle.text
                      }
                      tid={toggle.id}
                      ref={toggle.ref}
                      active={toggle.active}
                      onChange={(value) => {
                        const toggleList = toggles;
                        toggleList[index].active = value;
                        setToggles(toggleList);
                      }}
                    ></Toggle>
                  )
              )}
            {detail &&
              detail.map((desc, i) => (
                <Description key={i}>
                  {toggles[i].text + " "}
                  {desc}
                  {
                    <>
                      <br></br>
                      <br></br>
                    </>
                  }
                </Description>
              ))}
          </CategoryGrid>
        </>
      )}
    </Container>
  );
};

class Toggle extends Component {
  constructor(props) {
    super(props);
    this.state = { active: this.props.active };
    this.setToggle.bind(this);
  }

  setToggle(value) {
    this.setState({ active: !this.state.active });
    this.props.onChange(value);
  }

  render() {
    return (
      <>
        {!this.state.active ? (
          <CatButton onClick={() => this.setToggle(!this.state.active)}>
            <CheckBox
              type="checkbox"
              checked={this.state.active}
              onChange={(val) => this.setToggle(val)}
            />{" "}
            {this.props.text}
          </CatButton>
        ) : (
          <CatButtonSelected
            onClick={() => {
              this.setToggle(!this.state.active);
            }}
          >
            <CheckBox
              type="checkbox"
              checked={this.state.active}
              onChange={(val) => this.setToggle(val)}
            />
            {this.props.text}
          </CatButtonSelected>
        )}
      </>
    );
  }
}
