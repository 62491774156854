import { Avatar, Button } from "@mantine/core";
import React from "react";
import { BeautyButton } from "../buttons/BeautyButton";
import { ContentSection } from "../containers/ContentSection";
import { Link } from "react-router-dom";

export const AboutTheAuthor = (props) => {
  return (
    <div className="max-w-4xl mx-auto">
      {props.state && (
        <ContentSection
          customization="max-w-4xl"
          content={
            <div className="flex gap-8 justify-center items-start flex-wrap sm:flex-nowrap">
              <img
                src={props.state.authorImgUrl}
                className="w-32 h-32 shrink-0 object-cover"
              ></img>
              <div className="flex flex-col justify-between h-32">
                <div>
                  <h3 className="font-bold">{props.state.authorName}</h3>
                  <p className="text-gray-500 text-sm max-w-xl ">
                    {props.state.authorBio}
                  </p>
                </div>{" "}
                <Link
                  to={`/meer?authors=${encodeURIComponent(
                    props.state.authorName
                  )}`}
                >
                  <Button variant="outline">
                    Meer van {props.state.authorName}
                  </Button>
                </Link>
              </div>
            </div>
          }
        />
      )}
      {/* <div className="w-full flex justify-end -mt-4">
        <BeautyButton text="See Mark's Profile"></BeautyButton>
      </div> */}
    </div>
  );
};
