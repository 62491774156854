import { showNotification } from "@mantine/notifications";
import React from "react";
import tw from "twin.macro";
import sanitizeHtml from "sanitize-html";
const PostLink = tw.a`text-blue-500 underline cursor-pointer bg-gray-200 rounded-full py-1 px-2 mr-2`;

export async function DownloadContent(Parent) {
  //Get the document for this post
  const docRef = Parent.props.firestore
    .collection("documents")
    .doc(Parent.props.blogid);
  const doc = await docRef.get();

  //If this document exists (it should exist)
  if (doc.exists) {
    //Load the user data of the author of the document
    var authorRef = Parent.props.firestore
      .collection("users")
      .doc("unknown_user");
    if (doc.data().authorUID)
      authorRef = Parent.props.firestore
        .collection("users")
        .doc(doc.data().authorUID);
    const author = await authorRef.get();

    //Load checkout
    var checkout = doc.data().checkoutUrl;
    if (!checkout) checkout = "";

    //Go from the category map to a category list
    const categories = [];
    if (doc.data().categoryMap) {
      var categoryMap = doc.data().categoryMap;
      for (const [key, value] of Object.entries(categoryMap)) {
        categories.push(key);
      }
    }

    //Same thing for labels
    const labels = [];
    if (doc.data().labelMap) {
      var labelMap = doc.data().labelMap;
      for (const [key, value] of Object.entries(labelMap)) {
        labels.push(key);
      }
    }

    //Get current date if original article had no creation date
    var creationDate = doc.data().creationDate;
    if (!creationDate) creationDate = new Date();

    //Same for first letters
    //Old documents might not have first letters and when uploading without changing the title the parent state will be undefined: Below fixes this
    const firstLetter = doc.data().firstLetter
      ? doc.data().firstLetter
      : doc.data().title[0];
    const secondLetter = doc.data().secondLetter
      ? doc.data().secondLetter
      : doc.data().title[0];
    //Set the state according to the preprocessed downloaded values
    Parent.setState({
      originalArticle: doc.id,
      content: doc.data().text,
      title: doc.data().title,
      category: doc.data().category,
      originalAuthor: doc.data().authorUID,
      author: author.data(),
      authorUID: doc.data().authorUID,
      iconSrc: doc.data().imageURL,
      showAuthor: doc.data().showAuthor || false,
      showPublicist: doc.data().showPublicist || false,
      imgFitContain: doc.data().imgFitContain
        ? doc.data().imgFitContain
        : false,
      doc: doc,
      user: author,
      selectedDate: doc.data().selectedDate.toDate(),
      checkoutUrl: checkout,
      labels: labels,
      categories: categories,
      publicistName: doc.data().publicistName || "",
      authorName: doc.data().authorName || "",
      authorBio: doc.data().authorBio || "",
      authorSite: doc.data().authorSite || "",
      authorImgUrl: doc.data().authorImgUrl || "",
      batch: Parent.state.batch || doc.data().batch,
      docNr: doc.data().index,
      firstLetter: firstLetter || "",
      secondLetter: secondLetter || "",
      creationDate: creationDate,
      creationDateAsNumber: doc.data().creationDateAsNumber,
      parasjaDate:
        doc.data().parasjaDate && doc.data().parasjaDate.toDate
          ? doc.data().parasjaDate.toDate()
          : new Date(),
      dateAsNumber: doc.data().dateAsNumber,
      lastEditDate: doc.data().lastEditDate,
      customDate: doc.data().customDate,
      useCostumDate: doc.data().useCostumDate,
      customDateAsNumber: doc.data().customDateAsNumber,
      usePaymentLink: doc.data().usePaymentLink || false,
      useCustomSummary: doc.data().useCustomSummary || false,
      customSummary: doc.data().customSummary || "",
      useCustomAuthor: doc.data().useCustomAuthor || false,
      isPinned: doc.data().isPinned || false,
      isPinCat: doc.data().isPinCat || false,
      pincats: doc.data().pincats || [],
      visible: doc.data().visible || false,
      isHighlight: doc.data().isHighlight || false,
      pureHtml: doc.data().pureHtml || false,
    });
  }
}

export async function RequestRedaction(Parent) {
  if (Parent.state.user) {
    const data = {
      redactionRequests: Parent.state.user
        .data()
        .redactionRequests.concat([
          { name: Parent.state.title, id: Parent.state.originalArticle },
        ]),
    };

    const collectionRef = Parent.props.firestore.collection("users");

    const upload = collectionRef
      .doc(Parent.state.user.data().uid)
      .set(data, { merge: true })
      .then(
        function (docRef) {
          Parent.props.history.push("/blogs/" + Parent.props.blogid);
        }.bind(Parent)
      );
  }
}

export async function UploadContent(Parent, setVisible = false, returnToPage) {
  //Go from categroies list to map
  const categoryMap = {};
  for (var i = 0; i < Parent.state.categories.length; i++) {
    categoryMap[Parent.state.categories[i]] = true;
  }
  //Same for labels
  const labelMap = {};
  for (var i = 0; i < Parent.state.labels.length; i++) {
    labelMap[Parent.state.labels[i]] = true;
  }
  var authImgUrl = Parent.state.authorImageURL;
  if (!authImgUrl) authImgUrl = "";
  //Save current editor contents as data object

  //Modify image margins directly
  var text = Parent.state.content;

  //Replace &nbsp; carachters, not tested
  const nbspIndex = text.indexOf("nbsp;");
  // text = text.slice(0, nbspIndex - ) + text.slice(nbspIndex - 1, text.length);
  text = text.replaceAll(/&nbsp;/g, " ");
  // text = text.replaceAll(/nbsp;/g, " ");
  // text = text.replaceAll(/&/g, " ");

  var data = {
    imageURL: Parent.state.iconSrc,
    //Remove nbsps; from the HTML uploaded to the server. Also requested by Aart. Behaviour of this not tested. No nbsp; insertions at other front- or back-end code
    text: text,
    authorUID: Parent.state.authorUID,
    htmlfreetext: sanitizeHtml(
      text
        .replaceAll("<h2>", "<h2> ")
        .replaceAll("<h3>", "<h3> ")
        .replaceAll("<h4>", "<h4> ")
        .replaceAll("<h5>", "<h5> ")
        .replaceAll("<h6>", "<h6> ")
        .replaceAll("</h2>", ".</h2>")
        .replaceAll("</h3>", ".</h3>")
        .replaceAll("</h4>", ". </h4>")
        .replaceAll("</h5>", ".</h5>")
        .replaceAll("</h6>", ".</h6>")
        .replaceAll("<p>", "<p> ")
        .replaceAll("</p>", " </p>"),
      {
        allowedTags: ["svg"],
        allowedAttributes: {},
      }
    ),
    title: Parent.state.title,
    categoryMap: categoryMap,
    selectedDate: Parent.props.firebase.firestore.Timestamp.fromDate(
      Parent.state.selectedDate
    ),
    dateString: String(
      Parent.props.firebase.firestore.Timestamp.fromDate(
        Parent.state.selectedDate
      ).toDate()
    ),
    checkoutUrl: Parent.state.checkoutUrl,
    labelMap: labelMap,
    publicistName: Parent.state.publicistName || "",
    authorName: Parent.state.authorName || "",
    authorBio: Parent.state.authorBio || "",
    authorSite: Parent.state.authorSite || "",
    showAuthor: Parent.state.showAuthor || false,
    showPublicist: Parent.state.showPublicist || false,
    imgFitContain: Parent.state.imgFitContain
      ? Parent.state.imgFitContain
      : false,
    batch: Parent.state.batch,
    index: Parent.state.docNr,
    firstLetter: Parent.state.firstLetter,
    secondLetter: Parent.state.secondLetter,
    creationDate: Parent.state.creationDate,
    creationDateAsNumber: Parent.state.creationDate
      ? Parent.state.creationDate.toDate().getTime() / 1000
      : new Date().getTime() / 1000,
    authorImgUrl: Parent.state.authorImgUrl || "",
    dateAsNumber: new Date().getTime() / 1000,
    lastEditDate: new Date(),
    parasjaDate: Parent.state.parasjaDate || new Date(),
    customDate: Parent.state.customDate || new Date(),
    useCostumDate: Parent.state.useCostumDate || false,
    customDateAsNumber: Parent.state.customDate
      ? Parent.state.customDate.toDate
        ? Parent.state.customDate.toDate().getTime() / 1000
        : Parent.state.customDate.getTime() / 1000
      : new Date().getTime() / 1000,
    usePaymentLink: Parent.state.usePaymentLink || false,
    useCustomSummary: Parent.state.useCustomSummary || false,
    customSummary: Parent.state.customSummary || "",
    useCustomAuthor: Parent.state.useCustomAuthor || false,
    isPinned: Parent.state.isPinned || false,
    isPinCat: Parent.state.isPinCat || false,
    pincats: Parent.state.pincats || [],
    visible: setVisible || Parent.state.visible || false,
    isHighlight: Parent.state.isHighlight || false,
    pureHtml: Parent.state.pureHtml || false,
  };

  const collectionRef = Parent.props.firestore.collection("documents");
  const backupRef = Parent.props.firestore.collection("backups");
  if (Parent.state.originalArticle == null) {
    const upload = await collectionRef.add(data).then(
      function (docRef) {
        console.log("Document written with ID: ", docRef.id);
        Parent.setState({ originalArticle: docRef.id });
      }.bind(Parent)
    );
  } else if (Parent.state.originalArticle != null) {
    const backup = await backupRef
      .doc(Parent.state.originalArticle)
      .set(data)
      .then(console.log("backup succesfull"));

    //Add these AFTER creating upload data to the categorymap so that they are also resorted
    const resortCats = JSON.parse(JSON.stringify(categoryMap));
    console.log(
      "REMOVED CATEGORIES THAT NEED RESORTING",
      Parent.state.removedCats || []
    );
    var cats_that_where_rm_in_edit = Parent.state.removedCats || [];

    // Quick filter method for objects
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => ((res[key] = obj[key]), res), {});
    // If Parasja cat wa removed remove it from the metadata parasjot list
    var parasjotData = Parent.props.metadataDoc.parasjaList || {};
    if (cats_that_where_rm_in_edit.includes("Dagboek bij de najaarsfeesten")) {
      parasjotData = Object.filter(
        parasjotData,
        (el) => el.objid !== Parent.props.blogid
      );

      await Parent.props.firestore.collection("metadata").doc("batching").set(
        {
          parasjotData: parasjotData,
        },
        { merge: true }
      );
    }

    // And vice versa, if parasja cat was newly added, add this to the parasjalist
    if (Object.keys(resortCats).includes("Dagboek bij de najaarsfeesten")) {
      // Must be specific parsing
      parasjotData[Parent.props.blogid] = {
        objid: Parent.props.blogid,
        authorname: Parent.state.authorName,
        authorurl: Parent.state.authorImgUrl,
        title: Parent.state.title,
        imageurl: Parent.state.iconSrc,
        start: Parent.state.parasjaDate
          ? Parent.state.parasjaDate.toISOString().split("T")[0]
          : "", //"2022-08-05" is an example
        // parasjadate is actually dagboek najaarsfeesten date but does not matter
      };

      await Parent.props.firestore.collection("metadata").doc("batching").set(
        {
          parasjotData: parasjotData,
        },
        { merge: true }
      );
    }

    // Merge the removed cats with the resort cats
    for (const rm_cat of cats_that_where_rm_in_edit) {
      resortCats[rm_cat] = true;
    }
    localStorage.setItem("categoryResort", JSON.stringify(resortCats));

    const upload = collectionRef
      .doc(Parent.state.originalArticle)
      .set(data)
      .then(
        function (docRef) {
          console.log("Time to reload window");
          // Go to finished page
          if (returnToPage === "view")
            Parent.props.history.push("/blogs/" + Parent.props.blogid);
          if (returnToPage === "edit") {
            window.location.href = "/blogs/" + Parent.props.blogid + "/edit";
          }
          if (returnToPage === "dashboard")
            window.location.href =
              "/dashboard/" +
              Parent.state.originalArticle +
              "/" +
              String(categoryMap);
        }.bind(Parent)
      );
  }
}
