import React, { useEffect, useState } from "react";

import CMSConfig from "../CMSConfig";
import { Link } from "react-router-dom";
import { useToggleRefinement } from "react-instantsearch";

// Later we'll do our own styling with: https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/react/#style-your-widgets
import "instantsearch.css/themes/satellite.css";

// import { Pagination } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import {
  SearchBox,
  Pagination,
  useHits,
  InstantSearch,
  useRange,
  SortBy,
  useRefinementList,
  Configure,
} from "react-instantsearch";
import algoliasearch from "algoliasearch/lite";
import { DatePickerInput } from "@mantine/dates";
import { Button, Checkbox, Modal } from "@mantine/core";

const searchClient = algoliasearch(
  CMSConfig.algoliaClient,
  CMSConfig.algoliaSecret
);

function ControlledCheck({ attribute, currentRefinement, onRefine }) {
  const { value, canRefine, refine, sendEvent, createURL } =
    useToggleRefinement({ attribute: attribute });

  // For the initial loading
  useEffect(() => {
    refine({ isRefined: !currentRefinement });
  }, []);

  return (
    <Checkbox
      // type="checkbox"
      defaultChecked={currentRefinement}
      // checked={props.currentRefinement}
      label={`Toon alleen gepinde`}
      onChange={(e) => {
        refine({ isRefined: !e.target.checked });
        onRefine(e.target.checked);
      }}
    />
  );
}

function DateFilter() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { start, range, canRefine, refine } = useRange({
    attribute: "customDate._seconds",
  });
  const { min, max } = range;

  useEffect(() => {
    const filter = {};
    if (startDate) filter["min"] = Math.floor(+startDate / 1000);
    if (endDate !== null) filter["max"] = Math.floor(+endDate / 1000);
    if (Object.keys(filter).length > 0) {
      refine([filter.min, filter.max]);
    }
  }, [startDate, endDate]);

  return (
    <div className="flex justify-between gap-2 w-64">
      {" "}
      <DatePickerInput
        value={startDate}
        className="w-full"
        onChange={(e) => {
          setStartDate(e);
        }}
        label="Begindatum"
      />
      <DatePickerInput
        value={endDate}
        className="w-full"
        onChange={(e) => {
          setEndDate(e);
        }}
        label="Einddatum"
      />
    </div>
  );
}

function ControlledRefinementList({ attribute, currentRefinement, onRefine }) {
  const {
    items,
    hasExhaustiveItems,
    createURL,
    refine,
    sendEvent,
    searchForItems,
    isFromSearch,
    canRefine,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
  } = useRefinementList({
    attribute: attribute,
    limit: 5,
    showMore: true,
    // operator: "and",
  });
  const [hasInitRefined, setHasInitRefined] = useState(false);

  useEffect(() => {
    setHasInitRefined(true);
    if (!hasInitRefined && currentRefinement && currentRefinement.length > 0) {
      currentRefinement.forEach((item) => refine(item));
      // onRefine normally sets the parent controlled state. However, not needed because we are here doing initial refine from parent controlled state
    }
  }, [hasInitRefined, currentRefinement]);

  return (
    <div className="flex flex-col items-start gap-2">
      {items.map((item) => (
        <label key={item.label}>
          <Checkbox
            // type="checkbox"
            defaultChecked={currentRefinement.includes(item.label)}
            checked={item.isRefined}
            label={`${item.label} (${item.count})`}
            onChange={() => {
              if (canRefine) {
                const next = currentRefinement.includes(item.label)
                  ? currentRefinement.filter((c) => c !== item.label)
                  : [...currentRefinement, item.label];
                refine(item.value);
                onRefine(next);
              }
            }}
          />
        </label>
      ))}
      {canToggleShowMore && (
        <Button
          color="blue"
          size="xs"
          variant="outline"
          // className="bg-sky-600"
          onClick={toggleShowMore}
        >
          Show More
        </Button>
      )}
    </div>
  );
}

function Hit({ hit }) {
  return (
    <article className="w-64 rounded-md shadow-md cursor-pointer p-2">
      <img src={hit.imageURL} className="h-20" alt={hit.title} />
      <h1 className="font-bold">{hit.title}</h1>
      <p>{hit.authorName}</p>
      <p className="text-gray-600 line-clamp-4">{hit.htmlfreetext}</p>
    </article>
  );
}

function CustomHits(props) {
  const { hits, results, sendEvent } = useHits(props);

  return (
    <div className=" px-4">
      <div className="flex flex-wrap justify-center gap-4">
        {hits.map((hit) => {
          if (hit.visible)
            return (
              <Link
                key={hit.objectID}
                to={"/blogs/" + encodeURIComponent(hit.slug)}
              >
                <article
                  key={hit.objectID}
                  className="w-64 border h-80 rounded-md shadow-md cursor-pointer p-2 bg-white overflow-hidden flex flex-col justify-between"
                >
                  <img
                    className="rounded-md h-32 object-cover"
                    src={
                      hit.imageURL ||
                      "https://s3.eu-central-1.wasabisys.com/cis/imgs/CIS LOGO.png"
                    }
                    alt={hit.title + " post image"}
                  />
                  <div>
                    <h1 className="font-bold">{hit.title}</h1>
                    <p className="text-sm text-gray-800">
                      {hit.showAuthor && hit.authorName}
                    </p>
                    <p className="text-gray-600 line-clamp-3">
                      {hit.htmlfreetext}
                    </p>
                  </div>
                </article>
              </Link>
            );
        })}
      </div>
    </div>
  );
}

export default (props) => {
  const { width, height } = useViewportSize();

  return (
    <Modal
      opened={props.opened}
      size={width < 700 ? "95%" : "80%"}
      onClose={props.onClose}
    >
      <InstantSearch
        searchClient={searchClient}
        indexName={"date_desc"}
        insights
      >
        <Configure filters="visible:true" />
        <div className="-mt-8 z-50">
          <div className="flex flex-col md:flex-row w-full mx-auto gap-16 items-center justify-between m-4 p-4">
            <div className="w-0 md:w-64" />
            <SearchBox className="md:w-96 " />
            <SortBy
              className={"w-64 "}
              items={[
                // { label: "Title (alphabetical)", value: "title_asc" },
                { label: "Datum (nieuwste eerste)", value: "date_desc" },
                {
                  label: "Datum (oudste eerst)",
                  value: "date_asc",
                },

                {
                  label: "Relevance",
                  value: CMSConfig.algoliaIndexName || "instant_search",
                },
              ]}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row">
          <CustomHits {...props} />
        </div>
        <div className="flex w-full mx-auto justify-center md:justify-center p-4">
          <Pagination className="" />
        </div>
      </InstantSearch>
    </Modal>
  );
};
