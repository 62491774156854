import React from "react";

export const ContentSection = (props) => {
  return (
    <div
      className={`mx-auto max-w-5xl my-8 bg-white p-8 shadow-md rounded-lg ${props.customization}`}
    >
      <h3 className="font-base text-4xl text-center w-full">{props.title}</h3>
      {props.content || props.children}
    </div>
  );
};
