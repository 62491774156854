import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

// import LogoImage from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as InstaIcon } from "../../images/instagram-icon.svg";
import { ReactComponent as LinkedInIcon } from "../../images/linkedin-icon.svg";

import SimpleSubscribeNewsletter from "components/forms/SimpleSubscribeNewsletter";
const Container = tw.div`relative bg-gray-200 text-gray-800 -mx-8 -mb-8 mt-16 px-8`;
const ThreeCols = tw.div`max-w-screen-xl justify-center mx-auto py-16 lg:py-20 flex flex-col  md:flex-row justify-between`;

const Column = tw.div` mx-auto  p-2 mx-auto`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 -mx-2 lg:mx-0  text-sm font-medium w-full lg:w-full flex flex-col justify-between `;
const SimpleLink = tw.a`border-b-2 text-sky-700 border-transparent pb-1 transition-all duration-300 hover:text-blue-500`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block  p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition-all duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const ContactCell = tw.div`h-12  flex items-center pt-1`;
const Icon = tw.svg`mr-2 w-5 h-6 pb-1`;

export default (props) => {
  return (
    <Container>
      <ThreeCols>
        <Column>
          <LogoContainer>
            {/* <LogoImg src={LogoImage} /> */}
            <ColumnHeading>CIS</ColumnHeading>
          </LogoContainer>
          <CompanyDescription>
            We stellen het op prijs als u ons werk wil steunen. Gebed voor
            Israël is het belangrijkste! U kunt ons financieel steunen op IBAN
            NL55INGB0005999935 t.n.v. Stichting Centrum voor Israëlstudies.
            Giften zijn aftrekbaar voor de belasting, het CIS heeft een ANBI
            status. U kunt via de email informatie opvragen over het
            belastingvrij begunstigen via een legaat.
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/hetcis">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/hetcis/">
              <InstaIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/hetcis/">
              <LinkedInIcon></LinkedInIcon>
            </SocialLink>
          </SocialLinksContainer>
        </Column>
        <Column>
          <ColumnHeading>Mail</ColumnHeading>
          <SimpleSubscribeNewsletter
            firestore={props.firestore}
            firebase={props.firebase}
            functions={props.functions}
            negFilter={["Dagboek najaarsfeesten"]}
          ></SimpleSubscribeNewsletter>
        </Column>
        <Column>
          <ColumnHeading>Contact</ColumnHeading>
          <LinkList>
            <ContactCell className="group">
              <Icon
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </Icon>
              Oude Kerkweg 100, 6717 JS Ede
              {/* {CopyToClip("Oude Kerkweg 100, 6717 JS Ede", false, false)} */}
            </ContactCell>
            <ContactCell className="group">
              <Icon
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" />
                <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" />
              </Icon>
              Postbus 80, 6710 BB Ede
              {/* {CopyToClip("Postbus 80, 6710 BB Ede", false, false)} */}
            </ContactCell>

            <ContactCell className="group">
              <Icon
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              </Icon>
              <SimpleLink href="tel:085 2391616">085 2391616 &nbsp;</SimpleLink>{" "}
              <SimpleLink> Di, Do, Vr</SimpleLink>
              {/* {CopyToClip("0318-696577", false, false)} */}
            </ContactCell>

            <ContactCell className="group">
              <Icon
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
              </Icon>
              <SimpleLink href="mailto:info@hetcis.nl">
                info@hetcis.nl
              </SimpleLink>

              {/* {CopyToClip("info@hetcis.nl", false, false)} */}
            </ContactCell>
            {/* <ContactCell>
              <SimpleLink href="https://www.hetcis.nl/blogs/1B6py7SFfIgjtm5BQvud">
                Vacature Israëlwerker
              </SimpleLink>
            </ContactCell> */}
          </LinkList>
        </Column>
      </ThreeCols>
    </Container>
  );
};
