import React, { useState, useRef } from "react";
import SunEditor from "suneditor-react";
import "../styles/suneditor.min.css";

import plugins from "suneditor/src/plugins";
import { ImagePicker } from "./ImagePicker";
import sanitizeHtml from "sanitize-html";
import { NewImagePicker } from "./NewImagePicker/NewImagePicker";

export const SunEditorComponent = (props) => {
  const editor = useRef();

  const [openPicker, setOpenPicker] = useState(false);
  const [openNewPicker, setOpenNewPicker] = useState(false);
  const [openDocPicker, setOpenDocPicker] = useState(false);
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  var newImagePickerPlugin = {
    name: "new_image_picker",
    display: "command",
    // HTML title attribute (tooltip) - default: plugin's name
    title: "Voeg geuploade afbeeldingen of stock photo's toe.",
    // HTML to be append to button (icon)
    innerHTML:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M578.2 66.06C409.8 116.6 230.2 116.6 61.8 66.06C31 56.82 0 79.88 0 112v319.9c0 32.15 30.1 55.21 61.79 45.97c168.4-50.53 347.1-50.53 516.4-.002C608.1 487.2 640 464.1 640 431.1V112C640 79.88 609 56.82 578.2 66.06zM128 224C110.3 224 96 209.7 96 192s14.33-32 32-32c17.68 0 32 14.33 32 32S145.7 224 128 224zM474.3 388.6C423.4 380.3 371.8 376 320 376c-50.45 0-100.7 4.043-150.3 11.93c-14.14 2.246-24.11-13.19-15.78-24.84l49.18-68.56C206.1 290.4 210.9 288 216 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C357.7 194.7 362.7 192 368 192s10.35 2.672 13.31 7.125l109.1 165.1C498.1 375.9 488.1 390.8 474.3 388.6z"/></svg>',
    buttonClass: "none",

    action: function (core, targetElement) {
      console.log("using new imagepicker plugin");
      setOpenNewPicker(true);
    },

    add: function (core, targetElement) {
      console.log("using new imagepicker plugin");
      // setOpenNewPicker(true);
    },
  };

  var addDivPlugin = {
    name: "add_div_plugin",
    display: "command",
    // HTML title attribute (tooltip) - default: plugin's name
    title: "Voeg een div aan de broncode van het artikel toe.",
    // HTML to be append to button (icon)
    innerHTML:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-code" viewBox="0 0 16 16"><path d="M5.854 4.854a.5.5 0 1 0-.708-.708l-3.5 3.5a.5.5 0 0 0 0 .708l3.5 3.5a.5.5 0 0 0 .708-.708L2.707 8l3.147-3.146zm4.292 0a.5.5 0 0 1 .708-.708l3.5 3.5a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708-.708L13.293 8l-3.147-3.146z"/></svg>',
    buttonClass: "none",

    action: function (core, targetElement) {
      AddFunctionalDiv();
    },

    add: function (core, targetElement) {
      // AddFunctionalDiv();
    },
  };

  async function PickImageClosePicker(imgSrc) {
    setOpenNewPicker(false);
    await editor.current.insertHTML(
      `<div class="se-component se-image-container __se__float-left" contenteditable="false" style="margin-right:10px; margin-left:10px;"><figure ><img src="${imgSrc}" alt="" data-proportion="true" data-align="left" data-file-name=${imgSrc} data-file-size="0"  data-index="0"></figure></div>`,
      true
    );
    //await props.reloadMethod();
    //We have to do this so that the button is clickable again
  }

  async function AddFunctionalDiv() {
    await editor.current.insertHTML(`<div class="se-component"></div>`, true);
  }

  async function PickDocClosePicker(ret) {
    setOpenNewPicker(false);
    await editor.current.insertHTML(
      `<a download="${ret.url}" href="${ret.url}" alt="${ret.name}">${ret.name}</a>`,
      true
    );
    //await props.reloadMethod();
    //We have to do this so that the button is clickable again
  }

  async function RemoveStylesAndPaste(event, data, core) {
    event.preventDefault();
    const cleanedData = sanitizeHtml(data, {
      allowedTags: ["svg"],
    });
    await editor.current.insertHTML(cleanedData, false);
    editor.current.core.history.undo();
    return false;
  }

  return (
    <div>
      {openNewPicker && (
        <NewImagePicker
          parentFunction={(imgSrc) => {
            PickImageClosePicker(imgSrc);
          }}
          chooseMethodDoc={(doc) => {
            PickDocClosePicker(doc);
          }}
          metadataDoc={props.metadataDoc}
          opened={openNewPicker}
          setOpened={setOpenNewPicker}
          defaultImg={""}
          functions={props.functions}
          firestore={props.firestore}
          firebase={props.firebase}
        ></NewImagePicker>
      )}
      {/* Old */}
      {/* {openPicker && (
        <ImagePicker
          metadataDoc={props.metadataDoc}
          chooseMethod={(imgSrc) => {
            PickImageClosePicker(imgSrc);
          }}
          chooseMethodDoc={(doc) => {
            PickDocClosePicker(doc);
          }}
          closeMethod={() => setOpenPicker(false)}
          firebase={props.firebase}
          firestore={props.firestore}
          functions={props.functions}
        ></ImagePicker>
      )} */}
      <SunEditor
        style={{ height: undefined }}
        // onPaste={(event, data, t, core) =>
        //   RemoveStylesAndPaste(event, data, core)
        // }
        onCopy={() => {
          {
            return true;
          }
        }}
        setDefaultStyle={` font-size: 1.125rem; border:0px solid;  color: #2d3748; margin-top: 2.5rem; margin-bottom: 2.5rem; 
    line-height: 2;
    margin-top: 1.15rem;
   font-weight:300;
height: auto;
   font-family: inter;
table {border: none;}
  br {
    margin-top: 0.5rem; line-height: 2;
  }
  b, strong{
    font-weight:bold;
  }
  span {
    margin-top: 0; line-height: 2;
  }
  a {
    margin-top: 0.5rem; line-height: 2; color: #4299e1;
  }
  h2 {
    font-size: 1.875rem; font-weight: 700;  margin-top: 2.5rem;
  }
  h3 {
    font-size: 1.5rem; font-weight: 700;  margin-top: 2rem;
  }
  h4 {
    font-size: 1.25rem; font-weight: 700;  margin-top: 1.5rem;
  }
  ul {
    list-style-type: disc; list-style-position: inside;
    li {
      margin-left: 0.5rem;  margin-bottom: 0.75rem;
     
        margin-top: 0; display: inline; line-height: 1.5;
      
    }
  }
 `}
        getSunEditorInstance={getSunEditorInstance}
        disable={props.disable}
        hideToolbar={props.hideToolbar}
        defaultValue={props.defaultValue}
        onImageUpload={(
          targetElement,
          index,
          state,
          info,
          remainingFilesCount,
          core
        ) =>
          console.log(
            "change img align",
            targetElement,
            index,
            state,
            info,
            remainingFilesCount,
            core
          )
        }
        onChange={(contents, core) => props.onChange(contents, core)}
        setOptions={{
          plugins: [newImagePickerPlugin, addDivPlugin],
          resizingBar: false,
          attributesWhitelist: { all: "id" },

          formats: ["h2", "h3", "h4", "blockquote", "p"],
          font: ["sans-serif", "Arial", "Courier New,Courier"],
          buttonList: [
            ["undo", "redo"],
            ["formatBlock", "font", "fontSize", "fontColor"],
            ["blockquote"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            // "/", // Line break
            ["outdent", "indent"],
            ["align", "horizontalRule", "list"],
            ["new_image_picker"],

            ["table", "link", "image", "video", "audio" /** ,'math' */], // You must add the 'katex' library
            ["codeView", "add_div_plugin"],
            //at options to use the 'math' plugin.
            /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
          ], // Or Array of button list, eg. [['font', 'align'], ['image']]
          // Other option
        }}
      />
    </div>
  );
};
