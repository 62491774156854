import tw from "twin.macro";

export const Container = tw.div`relative`;
export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
export const ContentWithPaddingMd = tw.div`max-w-screen-md mx-auto py-20 lg:py-24`;
export const ContentWithPaddingLg = tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;
export const Content2Xl = tw.div`max-w-screen-2xl mx-auto`;
export const StyledDiv = tw.div`  min-h-screen text-gray-500 p-8 overflow-hidden`;
export const EmptyStyledDiv = tw.div`  text-gray-500 p-8 overflow-hidden`;

export const SimplePadding = tw.div`p-8`;
